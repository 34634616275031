import React from "react";

import { BrowserRouter as Router, Route, Switch, } from "react-router-dom";
import CampaignDetail from "./CampaignDetail.js";
import FilteredCampaigns from "./FilteredCampaigns.js";


export default class CampaignsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: [],
      totalCampaigns: 0,
      noNames: false,
      noRed: false,
      filmMode: false,
    }
    this.handleCampaignsChange = this.handleCampaignsChange.bind(this)
  }

  handleCampaignsChange(campaigns, totalCampaigns) {
    this.setState({ campaigns, totalCampaigns })
  }

  toggleFilmMode() {
    this.setState({
      noRed: !this.state.noRed,
      noNames: !this.state.noNames,
      filmMode: !this.state.filmMode,
    })
  }

  maybeFakeNames(campaigns) {
    if (!this.state.noNames) return campaigns;
    let name = "Muster Mustername";
    return campaigns.map(campaign => {
      return {
        ...campaign,
        name: name + " LLG",
        linkedInAccountName: name,
      };
    });
  }



  render () {
    return (
      <Router basename={this.props.basename}>
        <Switch>
          { this.props.canSeeDetails &&
              <Route
                exact
                path="/campaigns/:id"
                render={() =>  <CampaignDetail
                  campaigns={this.maybeFakeNames(this.state.campaigns)}
                  canEdit={this.props.canEdit}
                  basename={this.props.basename}
                  showConversion={this.props.showConversion}
                ></CampaignDetail>
                } />
          }
          <Route
            path="/"
            render={() => <FilteredCampaigns
              campaigns={this.maybeFakeNames(this.state.campaigns) || []}
              linkedInAccounts={this.props.linkedInAccounts}
              currentCompany={this.props.currentCompany}
              persistFilters={this.props.persistFilters}
              noRed={this.state.noRed}
              filmModeActive={this.state.noNames}
              toggleFilmMode={this.toggleFilmMode.bind(this)}
              totalCampaigns={this.state.totalCampaigns}
              admins={this.props.admins}
              searches={this.props.searches}
              users={this.props.users}
              currentUserId={this.props.currentUserId}
              currentAdminId={this.props.currentAdminId}
              canEdit={this.props.canEdit}
              context={this.props.context}
              canSeeColorCode={this.props.canSeeColorCode}
              canSeeDetails={this.props.canSeeDetails}
              canUseFilmMode={this.props.canUseFilmMode}
              onCampaignsChange={this.handleCampaignsChange}
              showAdminInfo={this.props.showAdminInfo && !this.state.filmMode}
              basename={this.props.basename}
              cleanStyle={this.props.cleanStyle}
              showConversion={this.props.showConversion}
              canExecuteCampaigns={this.props.canExecuteCampaigns}
              canExecuteLinkedInAccounts={this.props.canExecuteLinkedInAccounts}
            />} />
        </Switch>
      </Router>
    )
  }
}

