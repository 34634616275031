import React from "react";
import { present } from "../utils.js";
import { Segment } from 'semantic-ui-react';
import axios from "axios";
import Pluralize from 'react-pluralize'

export default class Notices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notices: [],
    }

    this.getNoticesToUser = this.getNoticesToUser.bind(this);
  }

  inflect(name) {
    if (name.slice(-1) == 's') {
      return name + "'"
    } else {
      return name + "'s"
    }
  }

  getNoticesToUser() {
    let url = `${this.props.prospectsEnrichPath || '/prospects_enrich'}/notices_to_user`
    axios.post(
      url,
      {
        id: this.props.id,
        maxNumberOfProspects: this.props.maxNumberOfProspects,
        filterParams: this.props.filterParams,
        dataProviders: this.props.dataProviders,
      }
    )
      .then(({ data }) => {
        this.setState({
          notices: data,
        })
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
      })
  }

  componentDidMount() {
    this.getNoticesToUser()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataProviders != this.props.dataProviders || prevProps.maxNumberOfProspects != this.props.maxNumberOfProspects) {
      this.getNoticesToUser()
    }
  }

  render() {
    if (!present(this.state.notices)) return null;

    let { totalProspects, numUnscrapableProspects, noConnectedScraperOn, timeEstimateDays, numNoAccountConnected, } = this.state.notices;

    return (
      <Segment>
        <h5 style={{ fontWeight: "bold", fontSize: 15 }}>LinkedIn Profile Scraper Summary</h5>
        <div>
          A total of <Pluralize singular={'prospect'} count={totalProspects} /> are considered for scraping. {numUnscrapableProspects == 0 ? "All are scrapable." : `${numUnscrapableProspects} are unscrapable.`}
        </div>
        {numNoAccountConnected > 0 && <div style={{ marginTop: 10 }}>
          <h5 style={{ fontWeight: "bold", }}>Not connected</h5>
          <div><Pluralize singular={'prospect'} count={numNoAccountConnected} /> are unscrapable because no account is connected to them.</div>
        </div>
        }
        {noConnectedScraperOn && noConnectedScraperOn.length > 0 && <div style={{ marginTop: 10 }}>
          <h5 style={{ fontWeight: "bold", }}>Turned off scrapers</h5>
          {
            noConnectedScraperOn.map((x, i) => {
              return <div key={i}>
                <span>Turn <a href={x.linkedInAccountLinkedInProfileScraperEditUrl}>{this.inflect(x.linkedInAccountName)} scraper on</a> to allow scraping of <Pluralize singular='prospect' count={x.unscrapable} />.</span>
              </div>
            })
          }
        </div>
        }
        <div style={{ marginTop: 10 }}>Estimated time for profile scraper to finish is {timeEstimateDays || 0} days.</div>
      </Segment>
    );
  }

}
