import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, withRouter, } from "react-router-dom";
import { Container, Table, Segment } from "semantic-ui-react";
import FlashMessage from "../../components/FlashMessage/index.js";
import axios from 'axios';

const Index = (props) => {
  const [campaignGroups, setCampaignGroups] = useState(props.campaignGroups || []);
  const [prospectDistributionRunning, setProspectDistributionRunning] = useState(props.prospectDistributionRunning || false);

  useEffect(() => {
    const fetchCampaignGroups = async () => {
      try {
        const response = await axios.get('/cold_calling.json');
        setCampaignGroups(response.data.campaignGroups);
        setProspectDistributionRunning(response.data.prospectDistributionRunning);
      } catch (error) {
        console.error('Failed to fetch campaign groups:', error);
      }
    };

    fetchCampaignGroups();
  }, []);

  let flashMessage = props.location.state?.flash

  if (prospectDistributionRunning) {
    return (
      <Container style={{ marginTop: 20 }}>
        {flashMessage && <FlashMessage
          error
          header='Error!'
          timeout={5000}
          removeOnTimeout
          content={flashMessage}
          onClose={() => props.history.replace()}
        />}
        <h1>Cold calling dashboard</h1>
        <Segment>
          An admin has recently updated the campaigns and searches of your company. 
          The system is currently recalculating which prospects should be contacted.
          Please check back in a couple of minutes.
        </Segment>
      </Container>
    );
  }

  if (campaignGroups.reduce((s, g) => s+g.actionsCount, 0) === 0) {
    return (
      <Container style={{ marginTop: 20 }}>
        {flashMessage && <FlashMessage
          error
          header='Error!'
          timeout={5000}
          removeOnTimeout
          content={flashMessage}
          onClose={() => props.history.replace()}
        />}
        <h1>Cold calling dashboard</h1>
        <Segment>
          There are no campaign groups with outstanding phone calls.
        </Segment>
      </Container>
    )
  }

  return (
    <Container style={{ marginTop: 20 }}>
      {flashMessage && <FlashMessage
        error
        header='Error!'
        timeout={5000}
        removeOnTimeout
        content={flashMessage}
        onClose={() => props.history.replace()}
      />}
      <h1>Cold calling dashboard</h1>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell># scheduled phone calls</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {campaignGroups.map((campaignGroup) => (
            <Table.Row key={campaignGroup.id}>
              <Table.Cell>
                <Link to={`/cold_calling/${campaignGroup.id}`}>{campaignGroup.name}</Link>
              </Table.Cell>
              <Table.Cell>
                {campaignGroup.actionsCount || 0}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Container>
  )
}

export default withRouter(Index);
