import React from 'react'
import { Dropdown, Input, Form, } from 'semantic-ui-react'

export default class AdminFilter extends React.Component {
  render () {
    let options = this.props.availableAdmins.map(admin => {
      return {
        key: String(admin.id),
        text: admin.email,
        value: String(admin.id),
      }
    });
    options.push({ key: "null", text: "Unassigned", value: "null" })
    return (

      <Form.Field>
        <label>Admins</label>
        <Dropdown
          placeholder='Admin Filter'
          fluid
          multiple
          selection
          options={options}
          search
          labeled
          value={this.props.filteredAdmins || []}
          onChange={this.props.onChange}
        />
      </Form.Field>
    )
  }
}
