import React from 'react'
import { BrowserRouter as Router, Route, Link, Switch, withRouter, } from "react-router-dom";
import axios from "axios";
import Statistic from "../shared/Statistic.js";


export default class CampaignDetail extends React.Component {
  constructor(props) {
    super(props);
    let preloadedCampaign = {};
    if (props.campaigns && props.campaigns.length > 0) {
      preloadedCampaign = props.campaigns.find(x => x.id === Number(props.match.params.id));
    }
    this.state = {
      campaign: preloadedCampaign,
      loading: true,
    }
    this.loadCampaignDetails();
  }

  componentDidMount() {
    window.scrollTo(0,0,);
  }

  spreadReversedOrEmptyArray(array) {
    if (!present(array)) { return [] }
    return [...array].reverse()
  }

  loadCampaignDetails() {
    let path = (this.props.basename||"")+"/campaigns/"+this.props.match.params.id+".json";
    axios.get(path)
      .then(({ data }) => {
        let currentName = this.state.campaign.name
        this.setState({
          loading: false,
          campaign: {
            ...this.state.campaign,
            ...data,
            name: currentName ? currentName : data.name,
          }
        });
      })
      .catch(error => {
        console.log("error when loading details ", error);
        this.setState({loading: true})
      });

  }


  render() {
    return <Statistic 
      model={this.state.campaign}
      loading={this.state.loading}
      showConversion={this.props.showConversion && this.state.campaign.numConversionCrmStatusReached !== null}
  />;
  }
}

CampaignDetail = withRouter(CampaignDetail);
