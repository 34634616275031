import React from 'react'
import { Dropdown, Input, Form, } from 'semantic-ui-react'

export default class LinkedInAccountOwnerFilter extends React.Component {
  render () {
    let options = this.props.availableOwners.map(owner => {
      return {
        key: String(owner.id),
        text: owner.name,
        value: String(owner.id),
      }
    });
    options.push({ key: "null", text: "Unowned", value: "null" })
    return (

      <Form.Field>
        <label>Owners</label>
        <Dropdown
          placeholder='Owner Filter'
          fluid
          multiple
          selection
          options={options}
          search
          labeled
          value={this.props.filteredOwners || []}
          onChange={this.props.onChange}
        />
      </Form.Field>
    )
  }
}

