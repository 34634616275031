import React from "react";
import { formInput, errorFor, } from "./utils.js";

export default class FixedOptionsFormInput extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.model[nextProps.name] !== this.props.model[this.props.name]) {
      return true;
    }
    if (errorFor(nextProps.model, nextProps.name) !== errorFor(this.props.model, this.props.name)) {
      return true;
    }
    return false;
  }

  render() {
    let { model, name, label, onChange, Element, props, htmlName } = this.props;
    return formInput(model, name, label, onChange, Element, props, htmlName);
  }
}

