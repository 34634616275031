import React from "react";
import { Header, Table, Form, Button, Icon, } from 'semantic-ui-react'
import CrmTaskForm from "./CrmTaskForm.js";


export default class CrmTaskFormSimpleOpenClose extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  onOpen() {
    this.setState({ open: true })
  }

  onClose() {
    this.setState({ open: false })
  }

  render() {
    let { users, defaultProspects, defaultCrmTask, trigger, onSave, } = this.props;
    let { open } = this.state
    return (
      <CrmTaskForm
        trigger={trigger}
        defaultProspects={defaultProspects}
        defaultCrmTask={defaultCrmTask}
        open={open}
        onOpen={this.onOpen}
        resetOnOpen={true}
        onClose={this.onClose}
        onSave={onSave}
        users={users}
      />
    );
  }
}
