import React from 'react'
import SwitchPercentageAbsolute from "../frontend/SwitchPercentageAbsolute.js"
import RoundFontAwesome from "../frontend/RoundFontAwesome.js";
import moment from "moment";
import { Header, Card, Placeholder, Grid, Button, Icon, } from 'semantic-ui-react'
import StatisticsTable from "../frontend/StatisticsTable.js";
import RepliedAfterStage from "../frontend/RepliedAfterStage.js";
import PartAndFull from "../frontend/PartAndFull.js";
import StatisticPerTimePeriodGraph from "../frontend/StatisticPerTimePeriodGraph.js";
import _ from "underscore"
import axios from "axios";

import GeneralTemplate from "./statistic/GeneralTemplate.js";
import CampaignGroupTemplate from "./statistic/CampaignGroupTemplate.js";

export default class Statistic extends React.Component {

  spreadReversedOrEmptyArray(array) {
    if (!present(array)) { return [] }
    return [...array].reverse()
  }

  renderStatBoxes(statistic) {
    return (
      <div className={`flex flex-space-between ${this.props.showConversion && "fourInARow"}`}>
        <StatBox
          faClasses={"far fa-envelope"}
          color="rgb(139, 85, 231)"
          part={statistic.numDelivered}
          full={statistic.nextMilestone}
          description="Requested"
        />
        <StatBox
          faClasses={"fas fa-user-check"}
          color="rgb(0, 32, 242)"
          part={statistic.numAccepted}
          full={statistic.numDelivered}
          description="Connected"
        />
        <StatBox
          faClasses={"fas fa-comment-dots"}
          color="rgb(17, 193, 210)"
          part={statistic.numAnswered}
          full={statistic.numAccepted}
          description="Responded"
        >
          <div className="stack with-margin">
            <RepliedAfterStage statistic={statistic} />
          </div>
        </StatBox>


        { this.props.showConversion &&
          <StatBox
            faClasses={"fa-solid fa-trophy"}
            color="rgb(216, 89, 80)"
            perMille
            part={statistic.numConversionCrmStatusReached}
            full={statistic.numDelivered}
            description="Converted"
          />
        }
      </div>
    );
  }

  render() {
    let model = this.props.model;
    let percent = Math.round(100*(model.numDelivered/model.targetAudienceSize))
    let relevantQueryStatistics = present(model.queryStatistics) ? model.queryStatistics.filter(s => s.numDelivered >= 90) : null;
    let relevantSearchStatistics = present(model.searchStatistics) ? model.searchStatistics.filter(s => s.numDelivered >= 100) : null;
    let relevantCampaignStatistics = present(model.campaignStatistics) ? model.campaignStatistics.filter(s => s.numDelivered >= 20) : null;

    return (
      <div className="campaignDetail">
        <Grid>
          <Grid.Row>
            <Grid.Column width={13}>
            
              <Header as="h1" style={{marginBottom: 30}}>
                { model.modelType } statistics
                <Header.Subheader>
                  {model.name}
                </Header.Subheader>
              </Header>
            </Grid.Column>
            { !window.location.href.includes("backend") && model.modelType === "Company" && <Grid.Column width={3} style={{textAlign: "right"}}>
              <a href="/company_statistics/export"><Button size="mini" icon labelPosition='left'><Icon name='download' />Export last 6 months</Button></a>
            </Grid.Column>
            }
          
          </Grid.Row>
        </Grid>

        { this.renderStatBoxes(model) }

        { (present(model.connectionRequestTypeStatistics) && model.connectionRequestTypeStatistics.length > 1) ? model.connectionRequestTypeStatistics.map(statistic => {
          return (
            <div key={statistic.id}>
              <Header as="h3" style={{marginBottom: 0, marginTop: 20}}>
                {statistic.name}
              </Header>
              { this.renderStatBoxes(statistic) }
            </div>
          );
        }) : null }



       <StatisticPerTimePeriodGraph
          loading={this.props.loading}
          monthly={this.spreadReversedOrEmptyArray(model.monthlyStatistics)}
          weekly={this.spreadReversedOrEmptyArray(model.weeklyStatistics)}
          daily={this.spreadReversedOrEmptyArray(model.dailyStatistics)}
          segments={this.spreadReversedOrEmptyArray(model.segmentedStatistics)}
          queries={this.spreadReversedOrEmptyArray(relevantQueryStatistics)}
          searches={this.spreadReversedOrEmptyArray(relevantSearchStatistics)}
          campaigns={this.spreadReversedOrEmptyArray(relevantCampaignStatistics)}
          showConversion={this.props.showConversion}
        />

        {model.modelType !== 'CampaignGroup' && (
          <GeneralTemplate
            present={present}
            model={model}
            loading={this.props.loading}
            relevantQueryStatistic={relevantQueryStatistics}
            relevantSearchStatistics={relevantSearchStatistics}
            relevantCampaignStatistics={relevantCampaignStatistics}
            showConversion={this.props.showConversion}
          />
        )}

        {model.modelType === 'CampaignGroup' && (
          <CampaignGroupTemplate
            present={present}
            model={model}
            loading={this.props.loading}
            relevantQueryStatistic={relevantQueryStatistics}
            relevantSearchStatistics={relevantSearchStatistics}
            relevantCampaignStatistics={relevantCampaignStatistics}
            showConversion={this.props.showConversion}
          />
        )}
    </div>
    );
  }
}


class StatBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switched: false,
    };
  }

  percentString() {
    return Math.round(100*(this.props.part/this.props.full))+"%";
  }

  switch() {
    this.setState({switched: !this.state.switched})
  }

  render() {
    let switchable = !!this.props.children;
    let switched = this.state.switched;
    return (
      <div className="statBox">
        { switchable && <button className={"circular mini ui icon button toggle "+ (switched && "active")} onClick={this.switch.bind(this)}>
          <i className="icon fas fa-info"></i>
        </button>}
        <div className="symbolContainer">
          <RoundFontAwesome
            faClasses={this.props.faClasses}
            color={this.props.color}
            width="54px"
            fontSize="20px"
          />
        </div>
        { switched ? this.props.children : <div className="rightContainer">
          <PartAndFull className={"huge"} part={this.props.part} full={this.props.full} perMille={this.props.perMille}/>
          <div className="description">
            {this.props.description}
          </div>
        </div>
        }
      </div>
    );
  }
}

function present(x) {
  if (typeof x === "object" && _.isEmpty(x)) return false;
  if (Array.isArray(x) && x.length == 0) return false;
  if (typeof x === "undefined" || !x || (typeof x === "String" && x.trim() == "") || x == null) return false;
  return true;
}
