import React, { forwardRef } from "react";

import { Form, Button, Dropdown, Grid, Segment, Icon, Message, Header, Progress, Loader, Modal, } from 'semantic-ui-react';
import { present, errorFor, formInput, adaptOnChangeToClassicFormOnChange } from "../shared/utils.js";
import DatePicker from "react-datepicker";
import FixedMessage from "./FixedMessage.js";
import BigCheckmark from "./BigCheckmark.js";
import ProspectSelect from "./ProspectSelect.js";

export default class CrmTaskFormPresentation extends React.Component {
  constructor(props) {
    super(props)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  handleDateChange(newDate) {
    newDate.setHours(8)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    this.props.onChange({ target: { name: "dueDate", value: newDate.toISOString()} })
  }

  handleOnClose(event) {
    if (event.key === "Escape" && !present(this.props.crmTask.title)) {
      this.props.onForceClose();
    } else {
      this.props.onClose(event);
    }
  }

  render () {
    let { onChange, loading, onSubmit, crmTask, errorMessage, trigger, users,  successMessage, onOpen, open, onForceClose, defaultProspects, } = this.props
    let onClose = this.handleOnClose.bind(this)

    return (

      <React.Fragment>

        <Modal trigger={trigger} onClose={onClose} onOpen={onOpen} open={open}>
          <Modal.Header>
            <Grid>
              <Grid.Column width={15}>
                { present(crmTask.id) ? "Task details" : "Create Task" }
              </Grid.Column>
              <Grid.Column width={1}>
                <Icon name="close" onClick={onForceClose} style={{cursor: "pointer"}}/>
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content>
            { errorMessage && <Message
              error
              header={"An error occured"}
              content={errorMessage}
            /> }
            {successMessage && present(crmTask.errors) &&
                <Message
                  error
                  header={"Invalid"}
                  content={"Please correct the entries marked in red."}
                />
            }
            {successMessage && !present(crmTask.errors) &&
                <Message
                  success
                  header={"Success"}
                  content={successMessage}
                />
            }

            <Form>

              <Grid columns='equal'>
                <Grid.Column width={14}>
                  { formInput(crmTask, "title", "Title", onChange, Form.Input, { autoFocus: true }) }
                </Grid.Column>
                <Grid.Column width={2} style={{ display: "flex", justifyContent: "center", paddingTop: 30, }}>
                  <BigCheckmark
                    name="completed"
                    value={crmTask.completed}
                    onChange={onChange}
                  />
                </Grid.Column>

                <Grid.Column width={8}>
                  <ProspectSelect
                    model={crmTask}
                    name={"prospectId"}
                    label={"Associated with"}
                    onChange={onChange}
                    defaultProspects={defaultProspects}
                  />
                </Grid.Column>

                <Grid.Column width={8}>
                  {
                    formInput(
                      crmTask,
                      "userId",
                      "Assigned to",
                      onChange,
                      Form.Select,
                      {
                        options: users.map(u => { return { key: u.id, value: u.id, text: u.email } })
                      }
                    )
                  }
                </Grid.Column>

                <Grid.Column width={16}>
                  <Form.Field>
                    <label>Due Date</label>
                    <DatePicker
                      name={`fooobar__do__not_autcomplete`}
                      dateFormat={"dd.MM.yyyy"}
                      selected={(typeof crmTask.dueDate === "string" && crmTask.dueDate !== "") ? new Date(crmTask.dueDate) : crmTask.dueDate}
                      onChange={this.handleDateChange}
                      autoComplete="off"
                    />
                  </Form.Field>
                </Grid.Column>

                <Grid.Column width={16}>
                  <Form.Field>
                    <label>Notes</label>
                    <textarea value={crmTask.notes || ""} name="notes" onChange={onChange} />
                  </Form.Field>
                </Grid.Column>

                <Grid.Column width={16}>
                  { /* Hidden Submit Button first so this button is triggered on enter in a form field */ }
                  <Button style={{display: "none"}} onClick={onSubmit} loading={loading} ></Button>
                  <Button.Group>
                    <Button
                      loading={loading}
                      onClick={onForceClose}
                    >Cancel</Button>
                    <Button.Or />
                    <Button
                      positive
                      onClick={onSubmit}
                      loading={loading}
                    >Save &amp; Close</Button>
                  </Button.Group>
                </Grid.Column>
              </Grid>
            </Form>

          </Modal.Content>

        </Modal>
      </React.Fragment>

    )
  }
}
