import React from "react";
import Statistic from "../shared/Statistic.js";
import { Header, Grid } from 'semantic-ui-react'

export default class CompanyStatistics extends React.Component {
  render () {
    const { company, showConversion } = this.props
    return (
      <div style={{marginTop: 30}}>
        <Statistic
          model={company}
          loading={false}
          showConversion={showConversion}
        />
      </div>
    )
  }
}
