import React from "react";
import CrmTaskList from "./CrmTaskList.js";
import { Dropdown, Input, Form, Segment, Header, Placeholder, Loader, Grid, Message, Icon, Button, } from 'semantic-ui-react'
import { BrowserRouter as Router, Route, Link, Switch, withRouter, } from "react-router-dom";
import axios from "axios";
import { debounce } from "debounce";
import InfiniteScroll from 'react-infinite-scroller';
import { present, } from "./utils.js";
import UserFilter from "./UserFilter.js";
import CompletedStatusFilter from "./CompletedStatusFilter.js";
import CrmTaskFormSimpleOpenClose from "./CrmTaskFormSimpleOpenClose.js";
import CrmStatusFilter from "./CrmStatusFilter.js";
const queryString = require('query-string'); // TODO: replace with "qs" used in ProspectsFilter.js


export default class FilteredCrmTasks extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      didLoadOnce: false,
      nextPage: 1,
      hasMore: true,
    }
    this.didScrollToBottom = this.didScrollToBottom.bind(this);
    this.loadCrmTasks = this.loadCrmTasks.bind(this);
    this.handleCrmTaskRefreshed = this.handleCrmTaskRefreshed.bind(this);
    this.onNewTaskSaved = this.onNewTaskSaved.bind(this);
    this.debouncedLoadCrmTasks = debounce(this.loadCrmTasks, 200);
  }

  componentDidMount() {
    try {
      let savedSearch = localStorage.getItem(`lastSetCrmTasksSearch-${this.props.currentUserId}-${this.props.currentAdminId}`)

      if (savedSearch !== null && savedSearch === "") { return this.loadCrmTasks(); }
      if (savedSearch !== null && this.props.location.search === "") {
        this.props.history.push(this.props.location.pathname+savedSearch)
        return;
      }
    } catch(e) { console.log(e) }
    if (!this.props.location.search) {
      let defaultFilter = { statuses: ["false"], assignedTo: [""+this.props.currentUserId], }
      let query = queryString.stringify(defaultFilter, { arrayFormat: 'bracket' });
      this.props.history.push(this.props.location.pathname+"?"+query)
      return;
    }
    if (this.props.crmTasks.length === 0) { this.loadCrmTasks() }
  }

  fullPath(location) {
    return location.pathname+location.search;
  }

  removeTrailingSlash(url) {
    if (url.slice(-1) == "/") return url.slice(0, -1);
    return url
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.debouncedLoadCrmTasks(1);
    }
  }

  didScrollToBottom() {
    if (!this.state.loading) {
      this.loadCrmTasks(this.state.nextPage);
    }
  }

  handleCrmTaskRefreshed(crmTask) {
    let crmTasks = [...this.props.crmTasks];
    let crmTaskIndex = crmTasks.findIndex(t => t.id === crmTask.id)
    if (crmTaskIndex !== 0 && !present(crmTaskIndex)) return;
    crmTasks[crmTaskIndex] = crmTask;
    this.props.onCrmTasksChange(crmTasks)
  }

  loadCrmTasks(page=1) {
    let { pathname, search } = this.props.location
    let queryObject = { ...queryString.parse(search, {arrayFormat: 'bracket'}), page }
    let searchWithPage = "?"+queryString.stringify(queryObject, { arrayFormat: 'bracket' });
    let path = `/crm_tasks.json${searchWithPage}`;
    this.setState({ loading: true })
    axios.get(path)
      .then(({ data }) => {
        if (search !== this.props.location.search) {
          console.log("discarding request ", search, "page:", page);
          return // this request is for a search that is no longer active, we discard its results
        }
        this.setState({ didLoadOnce: true, loading: false, nextPage: page+1, hasMore: data.crmTasks.length > 0 });
        this.props.onCrmTasksChange(page === 1 ? data.crmTasks : [ ...this.props.crmTasks, ...data.crmTasks], data.totalSize);
        try {
          localStorage.setItem(`lastSetCrmTasksSearch-${this.props.currentUserId}-${this.props.currentAdminId}`, search);
        } catch(e) { console.log(e) }
      })
      .catch(error => {
        console.log("error when loading details ", error);
        this.setState({loading: false})
      });
  }

  onNewTaskSaved(crmTask) {
    this.props.onCrmTasksChange([crmTask, ...this.props.crmTasks, ]);
  }

  render() {
    let { crmTasks, users, } = this.props;
    return (
      <div style={{marginTop: 30}}>
        <Grid>

          <Grid.Row>
            <Grid.Column width={13}>
              <Header as='h2' floated='left'>
                Tasks ({ this.props.totalCrmTasks })
              </Header>
            </Grid.Column>
            <Grid.Column width={3} float="right" style={{textAlign: "right"}}>
              <CrmTaskFormSimpleOpenClose
                trigger={<Button labelPosition='left' icon><Icon name="add"></Icon>Create Task</Button>}
                onSave={this.onNewTaskSaved}
                defaultCrmTask={{ userId: this.props.currentUserId, }}
                users={users}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Filters
          loading={this.state.loading}
          crmStatuses={this.props.crmStatuses}
          crmTasks={crmTasks}
          users={users}
        />

        { <div style={{paddingBottom: 10}}>
          <InfiniteScroll
            initialLoad={false}
            loadMore={this.didScrollToBottom}
            hasMore={this.state.hasMore}
          >
            <CrmTaskList
              crmTasks={crmTasks}
              crmStatuses={this.props.crmStatuses}
              users={users}
              onCrmTaskRefreshed={this.handleCrmTaskRefreshed}
            />
            { this.state.loading && <Loader active inline='centered' /> }
          </InfiniteScroll>
        </div>
        }

        { this.props.crmTasks.length === 0 && this.state.didLoadOnce && <Grid.Column>
          <Message icon>
            <Icon name='filter' />
            <Message.Content>
              <Message.Header>No tasks match your filter</Message.Header>
              <p>You may wish to <Link to={this.props.location.pathname}>reset your filters</Link>.</p>
            </Message.Content>
          </Message>
        </Grid.Column> }

      </div>
    );
  }
}

FilteredCrmTasks = withRouter(FilteredCrmTasks)


class Filters extends React.Component {

  handleSearchChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    currentQuery.search = value;
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleAssignedToFilterChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    currentQuery.assignedTo = value;
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleStatusFilterChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    currentQuery.statuses = value;
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleCrmStatusFilterChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    currentQuery.crmStatuses = value;
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  render () {
    let { crmTasks, users, } = this.props;
    let query = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    let { search, assignedTo, statuses, crmStatuses, } = query
    return (
      <Segment>
        <h3>
          Filter&nbsp; &nbsp;
          { this.props.loading &&
              <Loader active inline size='tiny' className={ crmTasks.length === 0 ? "" : "delayVisibility" } />
          }
        </h3>
        <Form>
          <Grid>
            <Grid.Column width={16}>
              <Form.Input value={search || ""} autoFocus fluid label='Search...' placeholder='Search...' onChange={this.handleSearchChange.bind(this)}/>
            </Grid.Column>

            <Grid.Column width={6}><UserFilter
                filteredUsers={assignedTo}
                users={users}
                onChange={this.handleAssignedToFilterChange.bind(this)}
              /></Grid.Column>
            <Grid.Column width={4}><CompletedStatusFilter
                statuses={statuses}
                onChange={this.handleStatusFilterChange.bind(this)}
              /></Grid.Column>
            <Grid.Column width={6}><CrmStatusFilter
                crmStatuses={this.props.crmStatuses}
                onChange={this.handleCrmStatusFilterChange.bind(this)}
                filteredCrmStatuses={crmStatuses}
              /></Grid.Column>
          </Grid>
        </Form>
      </Segment>

    )
  }
}


Filters = withRouter(Filters)
