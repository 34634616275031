import React from "react";
import { present, formInput } from "./utils.js";
import { Message, Modal, Button, Icon, Segment, Checkbox, Grid, Form, Header, Popup, Loader } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from "axios";
import Notices from "./ProspectsEnrich/Notices.js";

export default class ProspectsEnrichModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: null,
      sendEmailExport: false,
      exportInProgress: {},
      exportForm: {},
      open: false,
      dataProviders: [],
      maxNumberOfProspects: 500,
    }

    if (props.runningEnrichment) {
      let runningEnrichment = props.runningEnrichment;
      this.state.name = runningEnrichment.name;
      this.state.prospectsEnrichId = runningEnrichment.id;
      this.state.status = runningEnrichment.status;
      this.state.dataProviders = runningEnrichment.data_providers || runningEnrichment.dataProviders;
      this.state.sendEmailExport = runningEnrichment.send_export_to_email_when_done || runningEnrichment.sendExportToEmailWhenDone;
      this.state.crmStatusId = runningEnrichment.crm_status_id || runningEnrichment.crmStatusId;
      this.state.maxNumberOfProspects = runningEnrichment.max_number_of_prospects || runningEnrichment.maxNumberOfProspects;
    }
    this.handleEnrichStartClick = this.handleEnrichStartClick.bind(this);
  }

  handleEnrichStartClick() {
    this.setState({ loading: true, googleSheetUrl: null, status: null })

    let url = this.props.prospectsEnrichPath || '/prospects_enrich';

    axios.post(
      url,
      {
        ...this.state.exportForm,
        filterParams: this.props.filterParams,
        name: this.state.name,
        sendEmailExport: this.state.sendEmailExport,
        crmStatusId: this.state.crmStatusId,
        dataProviders: this.state.dataProviders,
        maxNumberOfProspects: this.state.maxNumberOfProspects,
      }
    ).then(({ data }) => {
      this.props.setProspectsEnrich(data);
    })
    .catch((error) => {
      console.error(error)
      this.setState({ loading: false, errorMessage: error.response.data?.errorMessage || error.message });
    })
  }

  render() {
    let { errorMessage, } = this.state

    if (this.props.dataProviders.length == 0) return null;

    return (
      <Modal
        onClose={() => this.setState({ open: false })}
        trigger={
          this.props.trigger ?
            (<div onClick={() => this.setState({ open: true })}>{this.props.trigger}</div>) :
            (<Button size="mini" icon labelPosition='left' onClick={() => this.setState({ open: true })}><Icon name='signal' />Enrich</Button>)
        }
        open={this.state.open}
      >
        <Modal.Header>
          <Grid>
            <Grid.Column width={15}>
              Prospects Enrich
            </Grid.Column>
            <Grid.Column width={1}>
              <Icon name="close" onClick={() => this.setState({ open: false })} style={{ cursor: "pointer" }} />
            </Grid.Column>
            { !this.props.hideIndexLink && (
              <Grid.Column width={16} style={{ fontSize: 14, paddingTop: 0 }}>
                <div>
                  <a href="/prospects_enrichments">Check your running enrichments</a>
                </div>
              </Grid.Column>
            )}
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            { errorMessage && <Message
              error
              header={"An error occured"}
              content={errorMessage}
            /> }

            <Form>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                {formInput(this.state, "name", "Name", (e) => (this.setState({ name: e.target.value })), Form.Input)}
              </div>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                {
                  formInput(
                    this.state,
                    "dataProviders",
                    "Data Providers",
                    (e) => (this.setState({ dataProviders: e.target.value })),
                    Form.Select,
                    {
                      options: this.props.dataProviders.map(provider => ({ key: provider.key, text: provider.name, value: provider.key })),
                      search: true,
                      multiple: true,
                    }
                  )
                }
              </div>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                {!this.props.hideCrmStatusSelector && (
                  formInput(
                    this.state,
                    "crmStatusId",
                    "Set status of all enriched prospects to",
                    (e) => (this.setState({ crmStatusId: e.target.value })),
                    Form.Select,
                    {
                      options: this.props.crmStatuses.map(status => ({ key: status.id, text: status.name, value: status.id })),
                      search: true,
                      clearable: true,
                    },
                    "enrichCrmStatusId"
                  )
                )}
              </div>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                {
                  formInput(
                    this.state,
                    "maxNumberOfProspects",
                    "Maximum number of prospects to enrich",
                    (e) => (this.setState({ maxNumberOfProspects: e.target.value })),
                    Form.Input,
                    { type: 'number' },
                  )
                }
              </div>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Checkbox
                  label='Send export to my email when done'
                  onChange={(_, data) => this.setState({ sendEmailExport: data.checked })}
                  checked={this.state.sendEmailExport}
                />
              </div>
            </Form>

            <Notices
              dataProviders={this.state.dataProviders}
              maxNumberOfProspects={this.state.maxNumberOfProspects}
              filterParams={this.props.filterParams}
              prospectsEnrichPath={this.props.prospectsEnrichPath}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={this.handleEnrichStartClick}
            disabled={this.state.loading}
          >
            Start Enrichment
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
