import React from "react";

import { Form, Button, Dropdown, Grid, Segment, Icon, Message, Header, Progress, Loader, Modal, } from 'semantic-ui-react';
import { present, errorFor, formInput, adaptOnChangeToClassicFormOnChange } from "../shared/utils.js";

export default class ProspectFormPresentation extends React.Component {
  constructor(props) {
    super(props)
  }

  handleOnClose(event) {
    if (event.key === "Escape" && !present(this.props.prospect.linkedInProfileUrl)) {
      this.props.onForceClose();
    } else {
      this.props.onClose(event);
    }
  }

  render () {
    let { onChange, loading, onSubmit, prospect, errorMessage, trigger, campaigns,  successMessage, onOpen, open, onForceClose, defaultProspects, } = this.props
    let onClose = this.handleOnClose.bind(this)
    return (

      <React.Fragment>

        <Modal trigger={trigger} onClose={onClose} onOpen={onOpen} open={open}>
          <Modal.Header>
            <Grid>
              <Grid.Column width={15}>
                { present(prospect.id) ? "Edit Prospect" : "Create Prospect" }
              </Grid.Column>
              <Grid.Column width={1}>
                <Icon name="close" onClick={onForceClose} style={{cursor: "pointer"}}/>
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content>
            { errorMessage && <Message
              error
              header={"An error occured"}
              content={errorMessage}
            /> }
            {successMessage && present(prospect.errors) &&
                <Message
                  error
                  header={"Invalid"}
                  content={"Please correct the entries marked in red."}
                />
            }
            {successMessage && !present(prospect.errors) &&
                <Message
                  success
                  header={"Success"}
                  content={successMessage}
                />
            }

            <Form>

              <Grid columns='equal'>
                <Grid.Column width={8}>
                  { formInput(prospect, "linkedInProfileUrl", "Profile Url", onChange, Form.Input, { autoFocus: true }) }
                </Grid.Column>
                <Grid.Column width={8}>
                  { formInput(prospect, "name", "Name", onChange, Form.Input) }
                </Grid.Column>
                <Grid.Column width={16}>
                  <Grid>
                      <Grid.Column width={6}>
                        {
                          formInput(
                            prospect,
                            "campaignId",
                            "Campaign",
                            onChange,
                            Form.Select,
                            {
                              options: campaigns.map(c => { return { key: c.id, value: c.id, text: c.name } }),
                              search: true,
                              clearSearchQueryAfterItemAdd: true,
                            }
                          )
                        }
                      </Grid.Column>
                      <Grid.Column width={10}>
                        { formInput(prospect, "threadUrl", "Thread Url", onChange, Form.Input) }
                      </Grid.Column>
                    </Grid>
                </Grid.Column>

                <Grid.Column width={16}>
                  { /* Hidden Submit Button first so this button is triggered on enter in a form field */ }
                  <Button
                    style={{display: "none"}}
                    onClick={onSubmit}
                    loading={loading}
                  ></Button>
                  <Button.Group>
                   <Button
                      loading={loading}
                      onClick={onForceClose}
                    >Cancel</Button>
                    <Button.Or />
                    <Button
                      type="submit"
                      positive
                      onClick={onSubmit}
                      loading={loading}
                    >Save &amp; Close</Button>
                  </Button.Group>
                </Grid.Column>
              </Grid>
            </Form>

          </Modal.Content>

        </Modal>
      </React.Fragment>

    )
  }
}

