import React from "react";
import { Header, Table, Form, Button, Icon, Popup, } from 'semantic-ui-react'
import ManuallyRequestedLinkedInOutreachForm from "./ManuallyRequestedLinkedInOutreachForm.js";
import axios from "axios";
import { present, renderProspectCampaignAssocForTableView, } from "./utils.js";
import moment from "moment";
import ManuallyRequestedLinkedInOutreachFormSimpleOpenClose from "./ManuallyRequestedLinkedInOutreachFormSimpleOpenClose.js";
import { Link, withRouter,   } from "react-router-dom";


export default class ManuallyRequestedLinkedInOutreachList extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <React.Fragment>
         { this.props.manuallyRequestedLinkedInOutreaches.length > 0 && <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{textAlign: "center"}}>Status</Table.HeaderCell>
              {!this.props.hideGoingTo && (
                <Table.HeaderCell>Going to</Table.HeaderCell>
              )}
              <Table.HeaderCell>Triggered from</Table.HeaderCell>
              <Table.HeaderCell>Sending via</Table.HeaderCell>
              <Table.HeaderCell>Author</Table.HeaderCell>
              {!this.props.hideCreatedAt && (
                <Table.HeaderCell>Created At</Table.HeaderCell>
              )}
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              this.props.manuallyRequestedLinkedInOutreaches.map(manuallyRequestedLinkedInOutreach => {
                return <ManuallyRequestedLinkedInOutreachListItem
                  campaigns={this.props.campaigns}
                  key={manuallyRequestedLinkedInOutreach.id}
                  manuallyRequestedLinkedInOutreach={manuallyRequestedLinkedInOutreach}
                  users={this.props.users}
                  onManuallyRequestedLinkedInOutreachRefreshed={this.props.onManuallyRequestedLinkedInOutreachRefreshed}
                  onManuallyRequestedLinkedInOutreachRemoved={this.props.onManuallyRequestedLinkedInOutreachRemoved}
                  routed={this.props.routed}
                  crmStatuses={this.props.crmStatuses}
                  hideGoingTo={this.props.hideGoingTo}
                  hideCreatedAt={this.props.hideCreatedAt}
                /> })
            }
          </Table.Body>
        </Table>
         }
      </React.Fragment>

    )
  }
}


class ManuallyRequestedLinkedInOutreachListItem extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      wasDeleted: false,
    }

    this.handleDelete = this.handleDelete.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.renderProspect = this.renderProspect.bind(this)
    this.findCrmStatus = this.findCrmStatus.bind(this)
  }

  l(date) {
    if (!present(date)) return "-"
    return moment(date).format("DD.MM.YYYY")
  }

  renderStatus() {
    let { manuallyRequestedLinkedInOutreach } = this.props
    let linkedInOutreach = manuallyRequestedLinkedInOutreach.linkedInOutreach;
    console.log("renderStatus", {manuallyRequestedLinkedInOutreach})
    if (present(linkedInOutreach)) {
      if (present(linkedInOutreach.repliedAt)) {
        return "Replied";
      }

      if (present(linkedInOutreach.acceptedConnectionRequestAt)) {
        return "Accepted";
      }

      if (present(linkedInOutreach.connectionRequestError)) {
        return (
          `Error: ${linkedInOutreach.connectionRequestError}`
        );
      }

      return (
        "Sent"
      );
    }

    if (present(manuallyRequestedLinkedInOutreach.error)) {
      return "Error: " + manuallyRequestedLinkedInOutreach.error
    }

    if (!present(linkedInOutreach) && present(manuallyRequestedLinkedInOutreach.lockedForSendingAt)) {
      return "Sending now"
    }



    return "Sending soon"
  }

  handleDelete() {
    if (!window.confirm("Do you really want to delete this outreach?")) {
      return;
    }
    this.setState({deleteLoading: true})
    axios.delete(`/manually_requested_linked_in_outreaches/${this.props.manuallyRequestedLinkedInOutreach.id}`)
      .then(({ data }) => {
        this.setState({
          deleteLoading: false,
          deleteErrorMessage: present(data.error) ? data.error : null, wasDeleted: data.success,
        })
        if (!present(data.error)) {
          this.props.onManuallyRequestedLinkedInOutreachRemoved && this.props.onManuallyRequestedLinkedInOutreachRemoved(this.props.manuallyRequestedLinkedInOutreach)
        }
      })
      .catch(error => {
        console.log("error submitting", error);
        this.setState({ deleteLoading: false, deleteErrorMessage: "Could not delete outreach. We don't know why this error occured. Please try again or contact us :).", })
      });
  }

  handleOpen() {
    let { manuallyRequestedLinkedInOutreach, } = this.props;
    this.props.history.push(`/manually_requested_linked_in_outreaches/${manuallyRequestedLinkedInOutreach.id}${this.props.location.search}`)
  }

  findCrmStatus(id) {
    return this.props.crmStatuses.find(s => s.id == id)
  }

  renderProspect(prospect) {
    if (!prospect) {
      return <span>-</span>;
    }
    let pathPrepend = this.props.location.pathname.replace(/\/prospects\/\d*/, "")
    return (
      <Link className="ui mini icon left labeled basic button" to={`${pathPrepend}/prospects/${prospect.id}${this.props.location.search}`} style={{minWidth: "100%", textAlign: "left", }}>
        <Icon name="eye" className="verticalCenter"></Icon><span style={{whiteSpace: "nowrap"}}>{ prospect.name }</span><br /><i style={{fontSize: 10, whiteSpace: "nowrap", }}>{ present(prospect.crmStatusId) ? this.findCrmStatus(prospect.crmStatusId).name : "" }</i>
      </Link>
    );
  }


  render() {
    if (this.state.wasDeleted) {
      return null;
    }
    let { manuallyRequestedLinkedInOutreach, users, campaigns, routed, } = this.props;
    let { prospect, linkedInOutreach, author, triggeringProspect, } = manuallyRequestedLinkedInOutreach;

    let campaign = prospect.prospectCampaignAssociations.find(pca => pca.campaign.id === manuallyRequestedLinkedInOutreach.campaignId)?.campaign
    campaign ||= campaigns.find(c => c.id === manuallyRequestedLinkedInOutreach.campaignId)

    return (
      <Table.Row>
        <Table.Cell>
          { this.renderStatus() }
        </Table.Cell>
        {!this.props.hideGoingTo && (
          <Table.Cell>
            { this.renderProspect(prospect) }
          </Table.Cell>
        )}
        <Table.Cell>
          { this.renderProspect(triggeringProspect) }
        </Table.Cell>
        <Table.Cell>
          {campaigns.find(c => c.id === manuallyRequestedLinkedInOutreach.campaignId) ? (
            <a href={`/campaigns/${campaign.id}`}>
              {campaign.name}
            </a>
          ) : <>{campaign.name}</>}
        </Table.Cell>

        <Table.Cell>
          { present(author) ? author.email : "-" }
        </Table.Cell>
        {!this.props.hideCreatedAt && (
          <Table.Cell>
            <span>
              { this.l(manuallyRequestedLinkedInOutreach.createdAt) }
            </span>
          </Table.Cell>
        )}
        <Table.Cell style={{ textAlign: "center" }}>
          <Button.Group vertical labeled icon size='mini'>
            { routed && <Button icon='edit' onClick={this.handleOpen} content='Edit' /> }
            { !routed &&
              <ManuallyRequestedLinkedInOutreachFormSimpleOpenClose
                defaultRequestedOutreach={manuallyRequestedLinkedInOutreach}
                renderTemplateVariables={false}
                campaigns={campaigns}
                onSave={this.props.onManuallyRequestedLinkedInOutreachRefreshed}
                trigger={<Button icon='edit' content='Edit' /> }
              />
            }

            <Popup
              content={this.state.deleteErrorMessage}
              open={present(this.state.deleteErrorMessage)}
              position='right center'
              trigger={<Button icon='trash' content='Delete' onClick={this.handleDelete} />}
            />
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    );
  }
}
ManuallyRequestedLinkedInOutreachList = withRouter(ManuallyRequestedLinkedInOutreachList)
ManuallyRequestedLinkedInOutreachListItem = withRouter(ManuallyRequestedLinkedInOutreachListItem)
