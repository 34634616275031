import React from 'react'
import { Header } from 'semantic-ui-react'
import { BrowserRouter as Router, Route, Link, Switch, withRouter, } from "react-router-dom";
import CampaignPreview from "../shared/CampaignPreview.js";
import { Placeholder, } from 'semantic-ui-react'


export default class CampaignList extends React.Component {
  constructor(props) {
    super(props)
    this.renderActions = this.renderActions.bind(this);
  }

  renderActions({ campaign }) {
    return (
      <div>
        { this.props.canEdit &&
            <a href={`/backend/companies/${campaign.companyId}/campaigns/${campaign.id}/edit`} className="ui button" aria-label="Edit">
              <i className="far fa-edit icon"></i>
            </a>
        }
        { this.props.canSeeDetails && this.props.context === "backend" &&
            <a href={[`/backend/companies/${campaign.companyId}/campaigns/${campaign.id}`, this.props.filmModeActive ? "?anonymize_data=true" : false].filter(Boolean).join("")} className="ui button" aria-label="Details">
              <i className="far fa-eye icon"></i>
            </a>
        }
        { this.props.canSeeDetails && this.props.context === "frontend" &&
          <Link to={`/campaigns/${campaign.id}`} className="ui button" aria-label="Details">
            <i className="far fa-eye icon"></i>
          </Link>
        }
      </div>
    );
  }

  renderCampaignPreviews() {
    if (this.props.campaigns.length === 0 && !this.props.didLoadOnce) {
      return (<div>
          {
            [0,1,2,3,4,5].map((i) => {
              return <div
                key={i}
                style={{ boxShadow: "2px 2px 22px #c2e1f1", fontSize: "18px", background: "#fff", marginBottom: "50px", borderRadius: "9px", padding: "20px 20px 20px 25px", border: "2px solid #53c1f8", }}
              >
                <Placeholder length={"full"} style={{width:"100%", maxWidth: "none"}}>
                  <Placeholder.Header>
                    <Placeholder.Line length={"very long"}/>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line length={"medium"}/>
                  </Placeholder.Header>
                </Placeholder>
              </div>
            })
          }
        </div>)
    }

    return this.props.campaigns.map(campaign => {
      return <CampaignPreview
        key={campaign.id}
        renderActions={this.renderActions}
        campaign={campaign}
        canSeeColorCode={this.props.canSeeColorCode}
        showAdminInfo={this.props.showAdminInfo}
        noRed={this.props.noRed}
        cleanStyle={this.props.cleanStyle}
        showConversion={this.props.showConversion}
        canExecuteLinkedInAccounts={this.props.canExecuteLinkedInAccounts}
      />
    });
  }

  render () {
    let cols = 6
    if (!this.props.cleanStyle) {
      cols++;
    }
    if (this.props.showConversion) {
      cols++;
    }
    if (this.props.campaigns.length === 0 && this.props.didLoadOnce) {
      return null;
    }
    return (
      <div className={`campaignsList cols-${cols}`}>
        <div className={`campaignsHead`}>
          <div className="col"></div>
          <div className="col text-left">Campaign</div>
          { !this.props.cleanStyle && <div className="col">Milestone</div> }
          <div className="col">Requested</div>
          <div className="col">Connected</div>
          <div className="col">Responded</div>
          { this.props.showConversion && <div className="col">Converted</div> }
          <div className="col"></div>
        </div>
        <div>
          { this.renderCampaignPreviews() }
        </div>
      </div>
    )
  }
}

