import React from "react";
import CampaignList from "./CampaignList.js";
import StatusFilter from "./StatusFilter.js";
import AdminFilter from "./AdminFilter.js";
import SearchFilter from "./SearchFilter.js";
import LinkedInAccountOwnerFilter from "./LinkedInAccountOwnerFilter.js";
import LinkedInAccountFilter from "./LinkedInAccountFilter.js";
import ProspectsLeftFilter from "./ProspectsLeftFilter.js";
import { Dropdown, Input, Form, Segment, Header, Loader, Grid, Message, Icon, Button, } from 'semantic-ui-react'
import { BrowserRouter as Router, Route, Link, Switch, withRouter, } from "react-router-dom";
import axios from "axios";
import { debounce } from "debounce";
import InfiniteScroll from 'react-infinite-scroller';
const queryString = require('query-string'); // TODO: replace with "qs" used in ProspectsFilter.js
import { present, } from "./utils.js";

export default class FilteredCampaigns extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      didLoadOnce: false,
      nextPage: 1,
      hasMore: true,
      textBoxValue: "default",
    }
    this.didScrollToBottom = this.didScrollToBottom.bind(this);
    this.loadCampaigns = this.loadCampaigns.bind(this);
    this.debouncedLoadCampaigns = debounce(this.loadCampaigns, 200);
  }

  componentDidMount() {
    setTimeout(() => { this.reloadCampaigns() }, 5000)
    if (this.props.persistFilters) {
      try {
        let savedSearch = localStorage.getItem(`lastSetCampaignsSearch-${this.props.currentUserId}-${this.props.currentAdminId}`)

        if (savedSearch !== null && savedSearch === "") { return this.loadCampaigns(); }
        if (savedSearch !== null && this.props.location.search === "") {
          this.props.history.push(this.props.location.pathname+savedSearch)
          return;
        }
      } catch(e) { console.log(e) }
      if (!this.props.location.search) {
        let defaultFilter = { stati: ["1"], }
        if (this.props.admins) { defaultFilter.admins = this.props.admins.filter(a => a.isSelf).map(a => a.id) }
        let query = queryString.stringify(defaultFilter, { arrayFormat: 'bracket' });
        this.props.history.push(this.props.location.pathname+"?"+query)
        return;
      }
    }
    if (this.props.campaigns.length === 0) { this.loadCampaigns() }
  }

  fullPath(location) {
    return location.pathname+location.search;
  }

  removeTrailingSlash(url) {
    if (url.slice(-1) == "/") return url.slice(0, -1);
    return url
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.debouncedLoadCampaigns(1);
    }
  }

  didScrollToBottom() {
    if (!this.state.loading) {
      this.loadCampaigns(this.state.nextPage);
    }
  }

  reloadCampaigns() {
    let { pathname } = this.props.location
    let path = (this.props.basename || "/") + this.removeTrailingSlash(pathname)+`.json?campaign_ids=${this.props.campaigns.map(c => c.id).join(",")}`;
    axios.get(path)
    .then(({ data }) => {
      const updatedCampaigns = this.props.campaigns.map(c => data.campaigns.find(c2 => c2.id === c.id) || c)
      this.props.onCampaignsChange(updatedCampaigns, this.props.totalCampaigns);
    })
    .catch(error => {
      console.log("error when loading details ", error);
    }).finally(() => {
      setTimeout(() => { this.reloadCampaigns() }, 5000)
    });
  }

  loadCampaigns(page=1) {
    let { pathname, search } = this.props.location
    let queryObject = { ...queryString.parse(search, {arrayFormat: 'bracket'}), page }
    let searchWithPage = "?"+queryString.stringify(queryObject, { arrayFormat: 'bracket' });
    let path = (this.props.basename || "/") + this.removeTrailingSlash(pathname)+`.json${searchWithPage}`;
    this.setState({ loading: true })
    axios.get(path)
      .then(({ data }) => {
        if (search !== this.props.location.search) {
          console.log("discarding request ", search, "page:", page);
          return // this request is for a search that is no longer active, we discard its results
        }
        this.setState({ didLoadOnce: true, loading: false, nextPage: page+1, hasMore: data.campaigns.length > 0 });
        this.props.onCampaignsChange(page === 1 ? data.campaigns : [ ...this.props.campaigns, ...data.campaigns], data.totalSize);
        if (this.props.persistFilters) {
          try {
            localStorage.setItem(`lastSetCampaignsSearch-${this.props.currentUserId}-${this.props.currentAdminId}`, search);
          } catch(e) { console.log(e) }
        }
      })
      .catch(error => {
        console.log("error when loading details ", error);
        this.setState({loading: false})
      });
  }

  render() {
    let { campaigns, } = this.props;
    return (
      <div>
        <Grid>

        <Grid.Row>
        <Grid.Column>
          <Header as='h2' floated='left'>
            Campaigns ({ this.props.totalCampaigns })
          </Header>

          {this.props.context === "backend" && this.props.currentCompany && (
            <Button.Group floated="right" size="mini" color="blue">
              <Button type="button" icon
                labelPosition="left"
                onClick={() => {
                  document.location.href = document.location.pathname + "/new?script_type=LLG"
                }}
              >

                <i className="fas fa-user-plus icon"></i>
                Add LLG
              </Button>
              <Button.Or />
              <Button type="button"
                labelPosition="right"
                onClick={() => {
                  document.location.href = document.location.pathname + "/new?script_type=LM"
                }}
                icon
              >
                <i className="fas fa-envelope icon"></i>
                LM campaign
              </Button>
            </Button.Group>
          )}


          { this.props.canUseFilmMode && <Button
            labelPosition='left'
            floated='right'
            icon
            className={this.props.filmModeActive ? "red" : "gray"}
            onClick={this.props.toggleFilmMode}
            style={{marginTop: 6}}
            size='mini'
          >
            <Icon name="camera" />
            { !this.props.filmModeActive ? "Screen Capture Mode" : "Recording..." }
          </Button>
          }
        </Grid.Column>
        </Grid.Row>
        </Grid>

      <Filters
        loading={this.state.loading}
        admins={this.props.admins}
        users={this.props.users}
        searches={this.props.searches}
        linkedInAccounts={this.props.linkedInAccounts}
        campaigns={campaigns}
        showSearchFilter={this.props.currentCompany}
      />

      <div style={{paddingBottom: 10}}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={this.didScrollToBottom}
          hasMore={this.state.hasMore}
        >
          <CampaignList
            campaigns={campaigns}
            didLoadOnce={this.state.didLoadOnce}
            noRed={ this.props.noRed }
            canEdit={this.props.canEdit}
            context={this.props.context}
            canSeeColorCode={this.props.canSeeColorCode}
            filmModeActive={this.props.filmModeActive}
            canSeeDetails={this.props.canSeeDetails}
            showAdminInfo={this.props.showAdminInfo}
            cleanStyle={this.props.cleanStyle}
            showConversion={this.props.showConversion}
            canExecuteCampaigns={this.props.canExecuteCampaigns}
            canExecuteLinkedInAccounts={this.props.canExecuteLinkedInAccounts}
          />
          { this.state.loading && <Loader active inline='centered' /> }
          </InfiniteScroll>
      </div>

      { this.props.campaigns.length === 0 && this.state.didLoadOnce && <Grid.Column>
        <Message icon>
          <Icon name='filter' />
          <Message.Content>
            <Message.Header>No campaigns match your filter</Message.Header>
            <p>You may wish to <Link to={this.props.location.pathname}>reset your filters</Link>.</p>
          </Message.Content>
        </Message>
      </Grid.Column> }

    </div>
    );
  }
}

FilteredCampaigns = withRouter(FilteredCampaigns)

class Filters extends React.Component {

  handleSearchChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    currentQuery.search = value;
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleStatusFilterChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    currentQuery.stati = value;
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleProspectsLeftFilterChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    currentQuery.maxProspectsLeft = value;
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleLinkedInAccountOwnerFilterChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    currentQuery.linkedInAccountOwners = value;
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleConnMsgTooLongChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    if (value === "false") {
      delete currentQuery.connMsgTooLong;
    } else {
      currentQuery.connMsgTooLong = value;
    }
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleLinkedInAccountFilterChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    currentQuery.linkedInAccounts = value;
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleSearchFilterChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    currentQuery.searches = value;
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleAdminFilterChange(_, { value }) {
    let currentQuery = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    currentQuery.admins = value;
    let newQueryString = queryString.stringify(currentQuery, { arrayFormat: 'bracket' });
    this.props.history.push(this.props.location.pathname+"?"+newQueryString)
  }

  handleFiltersReset(e) {
    this.props.history.push(this.props.location.pathname)
    e.preventDefault();
  }

  render () {
    let { campaigns } = this.props;
    let query = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})
    let { stati, admins, search, maxProspectsLeft, linkedInAccountOwners, searches, linkedInAccounts, connMsgTooLong } = query
    const activeFilters = Object.values(query).filter(v => v.length > 0 && v !== "null").length > 0
    let displayAdmins = this.props.admins && this.props.admins.length > 0

    return (
      <Segment>
        <h3>
          Filter&nbsp; &nbsp;
          { this.props.loading &&
              <Loader active inline size='tiny' className={ campaigns.length === 0 ? "" : "delayVisibility" } />
          }
        </h3>
        <Form>
          <Grid>
            <Grid.Column width={8}>
              <Form.Input value={search || ""} autoFocus fluid label='Search...' placeholder='Search...' onChange={this.handleSearchChange.bind(this)}/>
            </Grid.Column>
            <Grid.Column width={this.props.admins ? 4 : 8}>
              <StatusFilter
                stati={stati}
                onChange={this.handleStatusFilterChange.bind(this)}
              />
            </Grid.Column>

            { this.props.admins && <Grid.Column width={4}><LinkedInAccountFilter
                options={this.props.linkedInAccounts}
                selected={linkedInAccounts}
                onChange={this.handleLinkedInAccountFilterChange.bind(this)}
              /></Grid.Column>
            }
            { /* do not render in frontend */ }

            {(this.props.admins && this.props.admins.length > 0) && <Grid.Column width={4}><AdminFilter
                availableAdmins={this.props.admins}
                filteredAdmins={admins}
                onChange={this.handleAdminFilterChange.bind(this)}
              /></Grid.Column>
            }
            {this.props.admins && <Grid.Column width={(this.props.showSearchFilter ? 3 : 5) + (displayAdmins ? 0 : 1)}><LinkedInAccountOwnerFilter
                availableOwners={this.props.users}
                filteredOwners={linkedInAccountOwners}
                onChange={this.handleLinkedInAccountOwnerFilterChange.bind(this)}
              /></Grid.Column>
            }
            {this.props.searches && this.props.showSearchFilter && <Grid.Column width={4 + (displayAdmins ? 0 : 1)}><SearchFilter
                availableSearches={this.props.searches}
                filteredSearches={searches}
                onChange={this.handleSearchFilterChange.bind(this)}
              /></Grid.Column>
            }
            {this.props.admins && <Grid.Column width={(this.props.showSearchFilter ? 2 : 4) + (displayAdmins ? 0 : 1)} stretched><ProspectsLeftFilter
                onChange={this.handleProspectsLeftFilterChange.bind(this)}
                prospectsLeft={maxProspectsLeft}
              /></Grid.Column>
            }
            {this.props.admins &&
              <Grid.Column
                width={(3) + (this.props.showSearchFilter && !displayAdmins && 1 || 0) + (!this.props.showSearchFilter && !displayAdmins && 2 || 0)}
              >
                <Form.Field>
                  <label>Conn.Msg. too long</label>
                  <Dropdown
                    placeholder=''
                    fluid
                    selection
                    options={[{ key: "false", text: "All", value: "false" }, { key: "true", text: "Filtered", value: "true" }]}
                    labeled
                    value={connMsgTooLong ? "true" : "false"}
                    onChange={this.handleConnMsgTooLongChange.bind(this)}
                  />
                </Form.Field>
              </Grid.Column>
            }
            <Grid.Column width={8}>
              <Button size="tiny">Search</Button>
              { activeFilters && <a href="#" onClick={this.handleFiltersReset.bind(this)} style={{ marginLeft: 10 }}><Icon name="cancel"></Icon> Clear all filters</a> }
            </Grid.Column>
          </Grid>
        </Form>
      </Segment>

    )
  }
}
Filters = withRouter(Filters)
