import React from "react";

import { BrowserRouter as Router, Route, Link, Switch, withRouter, } from "react-router-dom";
import FilteredCrmTasks from "./FilteredCrmTasks.js";
import { present } from "./utils.js";
import ProspectView from "./ProspectView.js";


export default class CrmTasksDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crmTasks: [],
      totalCrmTasks: 0,
      tags: props.tags || [],
    }
    this.handleCrmTasksChange = this.handleCrmTasksChange.bind(this)
    this.renderCrmTasks = this.renderCrmTasks.bind(this)
    this.handleProspectChange = this.handleProspectChange.bind(this)
    this.singleCrmTaskChange = this.singleCrmTaskChange.bind(this)
    this.crmTaskAdded = this.crmTaskAdded.bind(this)
  }

  handleCrmTasksChange(crmTasks, totalCrmTasks) {
    if (!present(totalCrmTasks)) { totalCrmTasks = this.state.totalCrmTasks }
    this.setState({ crmTasks, totalCrmTasks })
  }

  singleCrmTaskChange(crmTask) {
    let crmTasks = [...this.state.crmTasks];
    let index = crmTasks.findIndex(p => p.id === crmTask.id)
    if (index < 0) return console.log("crmTask not found");
    crmTasks[index] = crmTask
    this.setState({ crmTasks })
  }

  crmTaskAdded(crmTask) {
    this.setState({ crmTasks: [crmTask, ...this.state.crmTasks, ] })
  }

  handleProspectChange(prospect) {
    let crmTasks = [...this.state.crmTasks]
    let tasksToEdit = crmTasks.filter(task => task.prospect.id == prospect.id)
    tasksToEdit.forEach(t => {
      t.prospect = {...prospect}
    })
    this.setState({ crmTasks })
  }

  renderCrmTasks() {
    return <FilteredCrmTasks
      crmTasks={this.state.crmTasks || []}
      currentUserId={this.props.currentUserId}
      users={this.props.users}
      totalCrmTasks={this.state.totalCrmTasks}
      onCrmTasksChange={this.handleCrmTasksChange}
      basename={this.props.basename}
      crmStatuses={this.props.crmStatuses}
    />
  }

  render () {
    return (
      <Router basename={this.props.basename}>
        <Route
          path="/crm_tasks/prospects/:prospectId/"
          exact
          render={(routeProps) =>
              <ProspectView
                crmStatuses={this.props.crmStatuses}
                users={this.props.users}
                id={routeProps.match.params.prospectId}
                onClose={() => { routeProps.history.push(`/crm_tasks${routeProps.location.search}`) } }
                currentUserId={this.props.currentUserId}
                onProspectChange={this.handleProspectChange}
                onCrmTaskChange={this.singleCrmTaskChange}
                onCrmTaskAdded={this.crmTaskAdded}
                campaigns={this.props.campaigns}
                tags={this.state.tags}
                setTags={(tags) => this.setState({ tags })}
                currentUserCanSeeProspectDetails={this.props.currentUserCanSeeProspectDetails}
              />
          }
        />
        <Switch>
          <Route
            path={"/crm_tasks/:taskId"}
            exact
            render={this.renderCrmTasks}
          />
          <Route
            path={"/crm_tasks"}
            render={this.renderCrmTasks}
          />
        </Switch>
      </Router>
    )
  }
}



