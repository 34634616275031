import React from "react";
import { Message, Icon } from "semantic-ui-react";


class FlashMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removed: false,
      animation: 'slideInFromTop 0.5s ease-out forwards',
    };

    this.closeMessage = this.closeMessage.bind(this);
  }

  componentDidMount() {
    this.setPosition();
    if (this.props.timeout) {
      this.timeout = setTimeout(() => {
        if (this.props.removeOnTimeout) {
          this.setState({
            animation: 'slideOutToTop 1s ease-out forwards'
          }, () => setTimeout(() => { this.setState({ removed: true }); this.props.onClose && this.props.onClose(); }, 1000));
        }
      }, this.props.timeout);
    }
    window.addEventListener('resize', this.setPosition);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    window.removeEventListener('resize', this.setPosition);
  }

  setPosition = () => {
    let parentElement = document.getElementsByClassName('backendWrapper')[0];

    if (!parentElement) return;
    const { right } = parentElement.getBoundingClientRect();

    this.setState({ parentRightOffset: right })
  }

  closeMessage = () => {
    this.setState({ removed: true })
    this.props.onClose && this.props.onClose();
  }

  render() {
    if (this.state.removed) return null;

    let width = 300
    const { content, success, error, ...messageProps } = this.props;

    return (
      <div style={{
        position: "fixed",
        left: (this.state.parentRightOffset - width - 20) || '70%',
        top: 10,
        zIndex: 10,
        width,
        animation: this.state.animation,
      }}>
        <Message success={success} error={error}>
          <Message.Content>
            {content}
            <Icon
              name="close"
              style={{ position: 'absolute', right: 10, cursor: "pointer" }}
              onClick={() => this.closeMessage()}
            />
          </Message.Content>
        </Message>
      </div>
    );
  }
}

export default FlashMessage;
