import React from "react";
import { present } from "../utils.js";
import { Segment, Header, Popup, Button, Icon } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class ProgressStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCopyTooltip: false
    }
    this.handleCopy = this.handleCopy.bind(this)
  }

  handleCopy() {
    this.setState({ showCopyTooltip: true })
    setTimeout(() => {
      this.setState({ showCopyTooltip: false })
    }, 3000);
  }

  render() {
    if (!present(this.props.running)) return null;

    return (
      <Segment>
        {this.props.running && (
          <p><b>Enrichment is running...</b></p>
        )}
        <p><b>{this.props.status}</b></p>
        <p>
          {!present(this.props.googleSheetUrl) &&
            <i>You can close this popup, we will let you know once the enrichment is finished.</i>
          }

          {present(this.props.googleSheetUrl) &&
            <>
              <Header as="h4">Google Sheet</Header>
              <React.Fragment>
                <Popup
                  content='Copied to clipboard!'
                  position='top center'
                  on='click'
                  open={this.state.showCopyTooltip}
                  trigger={<CopyToClipboard text={this.state.googleSheetUrl}><Button onClick={this.handleCopy} icon labelPosition='left'><Icon name='clipboard' />Copy Sheet Url</Button></CopyToClipboard>}
                />
              </React.Fragment>
              <a href={this.state.googleSheetUrl} target="_blank" style={{ marginLeft: 10 }}>
                <Button icon labelPosition='left'>
                  <Icon name='table' />
                  Open Google Sheet
                </Button>
              </a>
            </>
          }
        </p>
      </Segment>
    );
  }

}
