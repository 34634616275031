import React from 'react'
import { Dropdown, Input, Form, } from 'semantic-ui-react'

const options = [
  {
    key: "1",
    text: "Running",
    value: "1",
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: "2",
    text: "Paused with follow ups",
    value: "2",
    label: { color: 'orange', empty: true, circular: true },
  },
  {
    key: "3",
    text: "Paused fully",
    value: "3",
    label: { color: 'red', empty: true, circular: true },
  },
  {
    key: "4",
    text: "Finished with follow ups",
    value: "4",
    label: { color: 'orange', empty: true, circular: true },
  },
  {
    key: "5",
    text: "Finished fully",
    value: "5",
    label: { color: 'red', empty: true, circular: true },
  },
]
export default class StatusFilter extends React.Component {
  render () {
    return (

      <Form.Field>
        <label>Status</label>
        <Dropdown
          placeholder='Status Filter'
          fluid
          multiple
          selection
          options={options}
          search
          labeled
          value={this.props.stati || []}
          onChange={this.props.onChange}
        />
      </Form.Field>
    )
  }
}
