import React from "react";
import { Modal, Button, Icon, Form, Input, Label, Grid, Segment, Message, Loader, Popup } from 'semantic-ui-react';
import axios from "axios";
import { present } from "./utils.js";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import qs from "qs";
import Notices from "./ProspectsEnrich/Notices.js";
import ProgressStatus from "./ProspectsEnrich/ProgressStatus.js";

export default class ProspectsEnrichDisplayModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingStatistics: true,
      enrichmentName: props.prospectsEnrich.name || '',
      open: props.open || false,
    };

    this.handleCopy = this.handleCopy.bind(this);
    this.onClose = this.onClose.bind(this);
    this.pollEnrichmentTimeoutId
    this.pollStatisticsTimeoutId
    this.pollTime = process.env.NODE_ENV === 'test' ? 100 : 2000;
  }

  componentWillUnmount() {
    clearTimeout(this.pollEnrichmentTimeoutId)
    clearTimeout(this.pollStatisticsTimeoutId)
  }

  handleNameChange = (event) => {
    this.setState({ enrichmentName: event.target.value });
  };

  handleSave = () => {
    const { enrichmentName } = this.state;
    let { prospectsEnrich } = this.props;

    let url = `${this.props.prospectsEnrichPath || '/prospects_enrich'}/${prospectsEnrich.id}`

    axios.patch(url, { name: enrichmentName })
      .then(() => {
        this.setState({ notice: 'Enrichment updated!', errorMessage: null});

        if (this.props.setProspectsEnrich) {
          // Update the prospectsEnrich on the parent component
          prospectsEnrich.name = enrichmentName;
          this.props.setProspectsEnrich(prospectsEnrich);
        }

        setTimeout(() => this.setState({ notice: null }), 2500);
      })
      .catch(error => {
        console.log(error)
        this.setState({ errorMessage: error.response.data?.errorMessage || error.message });
      });
  };

  handleStopEnrichment = () => {
    if (!confirm("The enrichment can not be restarted later, are you sure?")) return;

    const { prospectsEnrich } = this.props;

    let url = `${this.props.prospectsEnrichPath || '/prospects_enrich'}/${prospectsEnrich.id}`;

    axios.delete(url)
      .then(() => {
        this.setState({ notice: 'Enrichment stopped!', errorMessage: null });

        prospectsEnrich.running = false;
        if (this.props.resetProspectsEnrich) {
          this.props.resetProspectsEnrich();
        } else if (this.props.setProspectsEnrich) {
          this.props.setProspectsEnrich(prospectsEnrich);
        }

        setTimeout(() => this.setState({ notice: null }), 2500);
      })
      .catch(error => {
        console.error(error)
        this.setState({ errorMessage: error.response.data?.errorMessage || error.message });
      });
  };

  renderDetail = (label, value) => {
    if (!present(value)) return null;

    return (
      <div style={{ marginTop: 5, marginBottom: 5 }}>
        <b size="large">{label}:</b>
        <div style={{ marginTop: 5, marginLeft: 5, display: 'inline-block' }}>{value.toString()}</div>
      </div>
    );
  };

  pollEnrichment = () => {
    let { prospectsEnrich } = this.props;

    if (!prospectsEnrich?.running) return;

    this.setState({ startedPollingEnrichment: true })

    let url = `${this.props.prospectsEnrichPath || '/prospects_enrichments'}/${this.props.prospectsEnrich.id}.json`;

    axios.get(url)
      .then(({ data }) => {
        prospectsEnrich = data;
        if (this.props.setProspectsEnrich) {
          this.props.setProspectsEnrich(prospectsEnrich)
        }
      })
      .catch(error => {
        console.log("error polling export ", error);
      })
      .finally(() => {
        if (this.props.prospectsEnrich.running) {
          this.pollEnrichmentTimeoutId = setTimeout(this.pollEnrichment, this.pollTime);
        }
      })
  }

  pollStatistics = () => {
    this.setState({ startedPollingStatistics: true })

    let url = `${this.props.prospectsEnrichPath || '/prospects_enrich'}/${this.props.prospectsEnrich.id}/statistics`;

    axios.get(url)
      .then(({ data }) => {
        this.setState({
          loadingStatistics: false,
          statistics: data,
        })
      })
      .catch(error => {
        console.log("error polling export ", error);
      })
      .finally(() => {
        if (this.props.prospectsEnrich.running) {
          this.pollStatisticsTimeoutId = setTimeout(this.pollStatistics, this.pollTime);
        }
      })
  }

  componentDidMount() {
    if (this.state.open) {
      this.pollEnrichment()
      this.pollStatistics()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open != this.state.open && this.state.open && !this.state.startedPollingEnrichment) {
      this.pollEnrichment()
    }

    if (prevState.open != this.state.open && this.state.open && !this.state.startedPollingStatistics) {
      this.pollStatistics()
    }
  }

  handleCopy() {
    this.setState({ showCopiedText: true })
    setTimeout(() => {
      this.setState({ showCopiedText: false })
    }, 3000);
  }

  formatDataProviderName(name) {
    return name.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  onClose = () => {
    this.props.onClose && this.props.onClose();
    this.setState({ open: false });
  }

  render() {
    let { prospectsEnrich, trigger } = this.props;
    const { enrichmentName, open, errorMessage,  } = this.state;

    let crmStatus = this.props.crmStatuses.find(s => s.id == prospectsEnrich.crmStatusId)?.name || "-";
    let formattedDataProviders = prospectsEnrich.dataProviders?.map(dp => this.formatDataProviderName(dp))?.join(', ') || "-";

    let positiveStatistics = this.state.statistics && this.state.statistics.providerStats.filter(st => st.noEmails > 0 || st.noPhones > 0)

    return (
      <Modal
        onClose={() => this.onClose()}
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={
          trigger ? <span onClick={() => this.setState({ open: true })}>{trigger}</span> : <Button icon="eye" content="View" onClick={() => this.setState({ open: true })} size="mini" />
        }
      >
        <Modal.Header>
          <Grid>
            <Grid.Column width={15}>
              Edit Enrichment
            </Grid.Column>
            <Grid.Column width={1}>
              <Icon name="close" onClick={() => this.onClose()} style={{ cursor: "pointer" }} />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          {present(errorMessage) && (
            <Message
              error
              header={"An error occured"}
              content={errorMessage}
            />
          )}
          <Form>
            <Form.Field>
              <label>Name</label>
              <Input value={enrichmentName} onChange={this.handleNameChange} name="name" placeholder="Enter name" />
            </Form.Field>
          </Form>
          <Segment>
            <h3><b>Details:</b></h3>
            {present(prospectsEnrich.filterParams) && !this.props.hideSearchFilters && !prospectsEnrich.filterParams.searchId && (
              <div>
                <b>Search Filters:</b>
                <a
                  href={`/prospects?${qs.stringify(prospectsEnrich.filterParams)}`}
                  style={{ marginTop: 5, marginLeft: 5, marginRight: 5, display: 'inline-block' }}
                  target="_blank"
                >
                  Open Prospects Index
                </a>
              </div>
            )}

            {this.renderDetail("Running", prospectsEnrich.running ? "Yes" : "No")}
            {this.renderDetail("Status", prospectsEnrich.status)}
            {prospectsEnrich.status?.includes('STOPPED') &&  (
              <i>This enrichment was stopped before it could finish. It cannot be restarted.</i>
            )}
            {this.renderDetail("Data Providers", formattedDataProviders)}
            {this.renderDetail("Change prospects CRM Status after enrich to", crmStatus)}
            {this.renderDetail("Max. Number of Prospects", prospectsEnrich.maxNumberOfProspects)}
            {this.renderDetail("Send Export to Email After Finish", prospectsEnrich.sendExportToEmailWhenDone ? "Yes" : "No")}

            {present(prospectsEnrich.googleSheetUrl) &&
              <div style={{ marginTop: 5, marginBottom: 5 }}>
                <b>Export CSV:</b>
                <a
                  href={prospectsEnrich.googleSheetUrl}
                  style={{ marginTop: 5, marginLeft: 5, marginRight: 5, display: 'inline-block' }}
                  target="_blank"
                >
                  {prospectsEnrich.googleSheetUrl.substring(0, 75) + '...'}
                </a>

                <CopyToClipboard text={prospectsEnrich.googleSheetUrl}>
                  <Button onClick={this.handleCopy} icon labelPosition='left' size="tiny">
                    <Icon name='clipboard' />{this.state.showCopiedText ? 'Copied!' : 'Copy Sheet Url'}
                  </Button>
                </CopyToClipboard>
              </div>
            }
          </Segment>

          { positiveStatistics && positiveStatistics.length > 0 && (
            <Segment>
              <h3><b>Statistics:</b></h3>
              <p>Total number of prospects: {this.state.statistics.noProspects}</p>
              <Grid>
                {this.state.statistics.providerStats.map((providerStat, index) => (
                  <Grid.Column key={index} width={8}>
                    <Segment key={index} style={{ margin: 0 }}>
                      <h3>{providerStat.provider}</h3>
                      <p>Number of emails obtained: {providerStat.noEmails}</p>
                      <p>Number of phones obtained: {providerStat.noPhones}</p>
                    </Segment>
                  </Grid.Column>
                ))}
              </Grid>
            </Segment>
          )}

          <Notices
            id={prospectsEnrich.id}
            prospectsEnrichPath={this.props.prospectsEnrichPath}
          />
          <ProgressStatus running={prospectsEnrich.running} status={prospectsEnrich.status} googleSheetUrl={prospectsEnrich.googleSheetUrl} />
        </Modal.Content>
        <Modal.Actions>
          {prospectsEnrich.running && (
            <Button color='red' onClick={this.handleStopEnrichment}>
              <Icon name='stop' /> Stop Enrichment
            </Button>
          )}
          <Button color='green' onClick={this.handleSave}>
            <Icon name='checkmark' /> Save
          </Button>
          {this.state.notice}
        </Modal.Actions>
      </Modal>
    );
  }
}
