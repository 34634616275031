import React from "react";
import CrmTaskFormPresentation from "./CrmTaskFormPresentation.js";
import axios from "axios";
import { present, } from "../shared/utils.js";
import { withRouter } from "react-router";

export default class CrmTaskForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.defaultState(),
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmitAndClose = this.handleSubmitAndClose.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleForceClose = this.handleForceClose.bind(this)
  }

  componentDidMount() {
    if (this.props.fetchCrmTask) {
      this.fetchCrmTask();
    }
  }


  handleChange(event) {
    let crmTask = { ...this.state.crmTask };
    crmTask[event.target.name] = event.target.value
    this.setState({ crmTask })
  }

  submitUrl() {
    return present(this.state.crmTask.id) ? `/crm_tasks/${this.state.crmTask.id}/update` : `/crm_tasks/create`
  }

  selectDataToSubmit(crmTask) {
    filterObject(crmTask, "createdAt")
    filterObject(crmTask, "updatedAt")
    filterObject(crmTask, "companyId")
    filterObject(crmTask, "errors")
    filterObject(crmTask, "prospect")
    filterObject(crmTask, "numOpenTasks")
    return crmTask;

    function filterObject(obj, key) {
      for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (i == key) {
          delete obj[key];
        } else if (typeof obj[i] == 'object') {
          filterObject(obj[i], key);
        }
      }
    }
  }

  defaultState() {
    return {
      crmTask: {
        ...this.props.defaultCrmTask,
        errors: [],
      },
      loading: false,
      errorMessage: null,
      successMessage: null,
    };
  }

  handleClose() {
    this.handleSubmitAndClose();
  }

  fetchCrmTask() {
    this.setState({loading: true})
    axios.get(`/crm_tasks/${this.props.defaultCrmTask.id}.json`)
      .then(({ data }) => {
        this.setState({
          loading: false,
          crmTask: data,
        })
      })
      .catch(error => {
        console.error("an error occured fetching prospect data", error);
      });
  }

  handleSubmitAndClose() {
    axios.post(this.submitUrl(), {
      crmTask: this.selectDataToSubmit({ ...this.state.crmTask }),
    })
      .then(({ data }) => {
        this.setState({
          loading: false,
          crmTask: data,
          successMessage: "Saved successfully",
        })
        if (!present(data.errors)) {
          this.props.onSave && this.props.onSave({...data, });
          this.props.onClose && this.props.onClose();
        }
      })
      .catch(error => {
        console.log("error submitting", error);
        this.setState({ loading: false, errorMessage: "We don't know why this error occured. Please try again or contact us :).", })
      });
  }

  handleForceClose() {
    this.props.onClose();
  }

  handleOpen() {
    this.setState({ ...this.defaultState() });
    this.props.onOpen && this.props.onOpen();
  }


  render () {
    let { crmTask, loading, errorMessage, successMessage, } = this.state
    let { trigger, users,  defaultProspects, open } = this.props;
    return (
      <CrmTaskFormPresentation
        onChange={this.handleChange}
        onSubmit={this.handleSubmitAndClose}
        onOpen={this.handleOpen}
        onClose={this.handleSubmitAndClose}
        onForceClose={this.handleForceClose}
        crmTask={crmTask}
        users={users}
        loading={loading}
        errorMessage={errorMessage}
        successMessage={successMessage}
        trigger={trigger}
        defaultProspects={defaultProspects}
        open={open}
      />
    )
  }
}
