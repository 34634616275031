import React from "react";

export default class PartAndFull extends React.Component {
  percentString() {
    if (this.props.perMille) {
      return (1000*(this.props.part/this.props.full)).toFixed(2);
    } else {
      return Math.round(100*(this.props.part/this.props.full));
    }
  }

  render() {
    if (this.props.part === null) {
      return (
        <div className={"partAndFull " + (this.props.className || "")}>
          <div className="bigNumber" aria-label={this.props.description}>
            n/a
          </div>
        </div>
      )
    }
    if (typeof this.props.full === "undefined") {
      return (
        <div className={"partAndFull " + (this.props.className || "")}>
          <div className="bigNumber" aria-label={this.props.description}>
            {this.props.part}
          </div>
        </div>
      );
    }

    return (
      <div className={"partAndFull " + (this.props.className || "")}>
        <div className="bigNumber" aria-label={this.props.description}>
          {this.props.full ? this.percentString() : "0"}<span className="percent">{this.props.perMille ? "‰": "%"}</span>&nbsp;
          <div className="smallNumber"> {this.props.part} </div>
        </div>
      </div>
    );
  }
}
