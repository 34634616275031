import React, { useState } from "react";
import filesize from "filesize";
import { Icon } from 'semantic-ui-react';

const Attachment = ({ attachment, attachmentSize, text, downloadUrl, showPreviewOnHover = false, hideIcon = false, hideSize = false }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <a
        target="_blank"
        href={downloadUrl}
        className="underline"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!showPreviewOnHover && (
          attachment.match(/(image|\.(jpeg|png|jpg|bpm|gif|svg))$/) && (<div style={{ height: 200, width: 200, display: 'flex', justifyContent: "flex-start", alignItems: "center" }}><img loading="lazy" src={downloadUrl} style={{ width: "auto", height: "auto", maxHeight: "100%", maxWidth: "100%" }} /></div>)
        )}
        {!hideIcon && (
          <Icon name="file download" />
        )}
        {text.split("/").reverse()[0]}
      </a>{" "}
      {!hideSize && (`(${filesize(attachmentSize)})`)}
      {showPreviewOnHover && isHovered && attachment.match(/(image|\.(jpeg|png|jpg|bpm|gif|svg))$/) && (
        <div
          style={{
            position: "absolute",
            bottom: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            width: "200px",
            height: "200px",
            border: "1px solid #ccc",
            backgroundColor: "#fff",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            zIndex: 100,
            padding: "5px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            loading="lazy"
            src={downloadUrl}
            style={{ width: "auto", height: "auto", maxHeight: "100%", maxWidth: "100%" }}
            alt="Preview"
          />
        </div>
      )}
    </div>
  );
};

export default Attachment
