import React from "react";
import { Table } from 'semantic-ui-react'
import { present } from "../shared/utils.js";
import { Dropdown, Input, Form, Segment, Header, Placeholder, Loader, Grid, Message, Icon, Button, } from 'semantic-ui-react'
import qs from "qs";

export default class FunnelStatistics extends React.Component {
  render () {
    let { crmStatuses, campaigns, users, } = this.props
    return (
      <div style={{marginTop: 40}}>
        <Grid>

          <Grid.Row>
            <Grid.Column>
              <Header as='h1' floated='left'>
                Funnel statistics
              </Header>

            </Grid.Column>
          </Grid.Row>
        </Grid>
        { this.props.funnelStatisticSets.map((set, i) => {
          return <Segment key={i}>
            <Header as="h2">{set.name}</Header>
            { set.funnelStatistics.map(statistic => {
              return <FunnelStatistic
                key={statistic.id}
                funnelStatistic={statistic}
                crmStatuses={crmStatuses}
                relativeChangeIndex={set.showRelativeChangeToStatusWithIndex}
                campaigns={campaigns}
                users={users}
              />
            })}

          </Segment>
        })}
      </div>
    )
  }
}

class FunnelStatistic extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      visibleOwnerIds: [],
    }
  }

  modelWithGid(gid) {
    if (!present(gid)) return { name: "" }
    if (gid === "general") return { name: "General" }
    let ids = this.parseRowUrl(gid)
    if (present(ids.campaignId)) {
      return this.modelWithId(ids.campaignId, this.props.campaigns)
    }
    if (present(ids.ownerId)) {
      return this.modelWithId(ids.ownerId, this.props.users)
    }
  }

  modelWithId(id, array) {
    if (!present(id)) return { name: "" }
    if (id === "general") return { name: "General" }
    return array.find(o => o.id === id)
  }

  colString(row, i) {
    if (i === 0) {
      return this.modelWithGid(row[i]).name;
    }
    if (i === 1) {
      return row[i]
    }
    if (!present(this.props.relativeChangeIndex)) {
      return `${row[i]} no change`
    }
    return `${row[i]} (${this.percentString(row[i], row[this.props.relativeChangeIndex[i-1]+1])})`
  }

  percentString(part, total) {
    if (total === 0) return "-"
    return Math.round(((part/total)*100))+"%"
  }

  queryString(hadStatus, triggeredBy) {
    let { funnelStatistic, } = this.props
    let { to, from, statuses, } = funnelStatistic
    let afterHaving = { status: statuses[0], to, from, }
    let ids = this.parseRowUrl(triggeredBy)
    afterHaving.triggeredByCampaign = ids.campaignId;
    afterHaving.triggeredByOwner = ids.ownerId;

    if (hadStatus === afterHaving.status) {
      hadStatus = null
    }
    return qs.stringify({ crmStatusHistory: { hadStatus, afterHaving, } }, { arrayFormat: 'bracket' });
  }

  parseRowUrl(url) {
    let ownerId = url.match(/users\/(\d+|null)/)
    ownerId = ownerId === "null" || !present(ownerId) ? null : Number(ownerId[1])
    let campaignId = url.match(/campaigns\/(\d+|null)/)
    campaignId = campaignId === "null" || !present(campaignId) ? null : Number(campaignId[1])
    return { campaignId, ownerId, }
  }


  campaignRowsWhereOwner(campaignRows, userId) {
    return campaignRows.filter(row => {
      let ids = this.parseRowUrl(row[0]);
      return present(ids.campaignId) && ids.ownerId === userId
    })
  }

  campaignRowsWithoutOwner(campaignRows) {
    return campaignRows.filter(row => {
      let ids = this.parseRowUrl(row[0]);
      return present(ids.campaignId) && !present(ids.ownerId)
    });
  }

  singleCell(header, row, col, i) {
    return <Table.Cell key={i+1}> { i === 0 ? this.colString(row, i) : <a href={"/prospects/?"+this.queryString(header[i], row[0])} target="_blank">{ this.colString(row, i) }</a> } </Table.Cell>
  }

  singleRow(header, row, i, rowProps) {
    return <Table.Row key={i} {...rowProps}>
      { row.map((col,i) => this.singleCell(header, row, col, i))}
    </Table.Row>
  }

  handleToggleVisibleOwnerId(ownerId, event) {
    if (event.target.tagName === "A") return;
    if (this.state.visibleOwnerIds.includes(ownerId)) {
      this.setState({visibleOwnerIds: [...this.state.visibleOwnerIds.filter(id => id !== ownerId) ]});
    } else {
      this.setState({visibleOwnerIds: [...this.state.visibleOwnerIds, ownerId]});
    }
  }


  render() {
    let { funnelStatistic, crmStatuses, campaigns, } = this.props
    let { table, name, statuses } = funnelStatistic
    let initStatus = statuses[0];
    table = [...table]
    let header = table.shift()
    let rows = [...table]
    rows.sort((a, b) => b[1]-a[1])
    let ownerRows = rows.filter(row => row[0] === "general" || (present(this.parseRowUrl(row[0]).ownerId) && !present(this.parseRowUrl(row[0]).campaignId)))
    let campaignRows = rows.filter(row => row[0].includes("campaigns/"))
    return (
      <React.Fragment>
        <Header as="h3">{ name }</Header>
        <Table celled className="funnelStatisticTable">
          <Table.Header>
            <Table.Row>
              { header.map((col, i) => {
                return <Table.HeaderCell key={i}>{ this.modelWithId(col, crmStatuses).name }</Table.HeaderCell>
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { ownerRows.map((row , i) => {
              let ownerId = Number(row[0].replace("users/", ""));
              return (
                <React.Fragment key={i}>
                  { this.singleRow(header, row, i, { onClick: this.handleToggleVisibleOwnerId.bind(this, ownerId), className: (row[i] === "general" ? "" : "dropdownTriggerRow"), }) }
                  { this.state.visibleOwnerIds.includes(ownerId) && this.campaignRowsWhereOwner(campaignRows, ownerId).map((row, i) => this.singleRow(header, row, i, { className: "dropdownRow", } )) }
                </React.Fragment>
              )
            }) }
            { this.campaignRowsWithoutOwner(campaignRows).map((row, i) => {
              return this.singleRow(header, row, i);
            })}
          </Table.Body>
        </Table>
      </React.Fragment>

    );
  }

}
