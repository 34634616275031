import pSBC from 'shade-blend-color';
import RoundFontAwesome from "../frontend/RoundFontAwesome.js";
import SwitchPercentageAbsolute from "../frontend/SwitchPercentageAbsolute.js"
import RadialProgress from "../frontend/RadialProgress.js"
import ScriptType from "../shared/ScriptType.js";
import React from "react";
import { Message, Popup, } from 'semantic-ui-react';
import ExportCampaignModal from './ExportCampaignModal.js'

export default class CampaignPreview extends React.Component {
  getColors() {
    let { numAnswered, numDelivered } = this.props.campaign;

    var borderColor = "rgb(109, 192, 243)"; // blau
    var shadowColor = pSBC(0.5, borderColor);

    if (!this.props.canSeeColorCode) { return { borderColor, shadowColor }; }

    let percentResponded = numAnswered/numDelivered;
    if (numDelivered < 250) {
      borderColor = "#BFC5D1";
      shadowColor = pSBC(0.2, borderColor);
    } else {
      if (percentResponded < 0.04 && !this.props.noRed) {
        borderColor = "rgb(254, 43, 56)"; // rot
        shadowColor = pSBC(0.3,  "rgba(254, 43, 56, 0.35)");
      }
      if (percentResponded >= 0.04) {
        borderColor = "rgb(109, 192, 243)"; // blau
        shadowColor = pSBC(0.5, borderColor);
      }

      if (percentResponded > .08) {
        borderColor = "rgb(238, 130, 238)";
        shadowColor = pSBC(0.5, "rgba(238, 130, 238, 0.55)");
      }
    }
    return { borderColor, shadowColor };
  }

  getColorForLastSevenDaysStatistic() {
    if (!this.props.campaign.lastSevenDaysStatistic) { return null }

    let { numAnswered, numDelivered } = this.props.campaign.lastSevenDaysStatistic;

    if (!this.props.canSeeColorCode) { return null; }

    var color = "rgb(109, 192, 243)"; // blau

    let percentResponded = numAnswered/numDelivered;
    if (numDelivered < 150) {
      color = null;
    } else {
      if (percentResponded < 0.04 && !this.props.noRed) {
        color = "rgb(253, 127, 130)";
      }
      if (percentResponded >= 0.04) {
        color = "rgb(109, 192, 243)"; // blau
      }

      if (percentResponded > .08) {
        color = "rgb(238, 130, 238)";
      }
    }
    return color;

  }

  shortAccountType(type) {
    if (type === "STANDARD") {
      return "std."
    }
    if (type === "PREMIUM") {
      return "pre."
    }
    if (type === "SALES_NAVIGATOR") {
      return "sn."
    }
    if (type === "RECRUITER") {
      return "rec."
    }
    return "unknown"
  }

  render () {
    let { campaign, canExecuteLinkedInAccounts } = this.props;
    let { borderColor, shadowColor } = this.getColors();
    let displayNoMoreProspectsLeftAlert = this.props.showAdminInfo && (campaign.status === "1" || campaign.status === "7") && campaign.numAssignedNotContactedProspects <= 100
    let displayCompanyCreditsEmptyAlert = this.props.showAdminInfo && (campaign.status === "1" || campaign.status === "7") && campaign.companyCreditsEmpty

    let accountLevelPreviewLinks = () => {
      if (campaign.linkedInAccountExecutionLevel !== 'linked_in_account' || !campaign.linkedInAccountId) { return(<></>)}

      return(
        <>
          <a href={campaign.linkedInAccountNextProspectsUrl+"?limit=5000000"}>Next Prospects&nbsp;&nbsp;&nbsp;</a>
          {canExecuteLinkedInAccounts && (
            <>
              <a href={`/backend/executions/bygid?id=gid://llg-app/LinkedInAccount/${campaign.linkedInAccountId}`} target="_blank">Account Executions&nbsp;&nbsp;&nbsp;</a>
              <a href={`/backend/motion_files?bucket=LinkedInAccount/${campaign.linkedInAccountId}`} target="_blank">Files&nbsp;&nbsp;&nbsp;</a>
              <a href={`/backend/linked_in_accounts/${campaign.linkedInAccountId}/execute`} target="_blank">Launch&nbsp;&nbsp;&nbsp;</a>
            </>
          )}
        </>
      )
    }

    return (
      <div
        className={`campaignListItem campaignPreview ${this.props.cleanStyle ? 'clean' : 'verbose'}`}
        data-test-id={`campaign-preview-${campaign.id}`}
        style={{ borderColor, boxShadow: "2px 2px 16px "+ shadowColor, position: "relative", }}
      >
        <ScriptType scriptType={campaign.scriptType} backgroundColor={borderColor} />
        <div style={{position: "relative", width: "220px", marginLeft: "-30px", marginTop: "-30px"}}>
          { !this.props.cleanStyle && <RadialProgress
            status={statusReducer(campaign)}
            full={campaign.nextMilestone}
            part={campaign.numDelivered}
          />
          }
          <div>
            <CampaignStatus
              campaign={campaign}
              displayNoMoreProspectsLeftAlert={displayNoMoreProspectsLeftAlert}
              displayCompanyCreditsEmptyAlert={displayCompanyCreditsEmptyAlert}
              lastSevenDaysStatisticColor={this.props.showAdminInfo && this.getColorForLastSevenDaysStatistic()}
              showAdminInfo={this.props.showAdminInfo}
              cleanStyle={this.props.cleanStyle}
            />
          </div>
        </div>
        <div>
          <h2 className="ui header">
            <div className="content">
              {campaign.name}
              <div className="sub header">
                via Account: {campaign.linkedInAccountName}
                { this.props.showAdminInfo && <span>&nbsp;({this.shortAccountType(campaign.linkedInAccountType)})</span> }
                { (campaign.linkedInAccountGotVerifyIdentityRestrictionAt) && <span> &nbsp;(vid-lkd.)</span>}
              </div>
            </div>
          </h2>
        </div>

        { !this.props.cleanStyle && <div>
          <span className={`centerSymbol ${campaign.nextMilestone || "red"}`}>
            <RoundFontAwesome faClasses={"fas fa-flag-checkered"} color="rgb(55, 171, 61)" />
            <span>{campaign.nextMilestone || "--"}</span>
          </span>
        </div>
        }
        <div>
          <span className="centerSymbol">
            <RoundFontAwesome faClasses={"far fa-envelope"} color="rgb(139, 85, 231)" />
            { this.props.cleanStyle ?  <span>{campaign.numDelivered || "--"}</span> : <SwitchPercentageAbsolute
              aria-label={"Requested"}
              part={campaign.numDelivered}
              full={campaign.nextMilestone}
            /> }
          </span>
        </div>
        <div>
          <span className="centerSymbol">
            <RoundFontAwesome faClasses={"fas fa-user-check"} color="rgb(0, 32, 242)" />
            <SwitchPercentageAbsolute
              aria-label={"Connected"}
              part={campaign.numAccepted}
              full={campaign.numDelivered}
            />
          </span>
        </div>
        <div>
          <span className="centerSymbol">
            <RoundFontAwesome faClasses={"fas fa-comment-dots"} color="rgb(17, 193, 210)" />
            <SwitchPercentageAbsolute
              aria-label={"Responded"}
              part={campaign.numAnswered}
              full={campaign.numAccepted}
            />
          </span>
        </div>
        { this.props.showConversion && <div>
          <span className="centerSymbol">
            <RoundFontAwesome faClasses={"fa-solid fa-trophy"} color="rgb(216, 89, 80)" />
            <SwitchPercentageAbsolute
              aria-label={"Converted"}
              perMille
              part={campaign.numConversionCrmStatusReached}
              full={campaign.numDelivered}
            />
          </span>
        </div>
        }
        <div className="ui icon vertical buttons actions" style={{marginLeft: 10}}>
          { this.props.renderActions(this.props) }
        </div>
        { this.props.showAdminInfo &&
          <div style={{gridColumn: "2/ span 6", paddingTop: 15, }}>
            <b>Total:</b> {campaign.numProspects || 0}&nbsp;&nbsp;
            <span style={{ color: displayNoMoreProspectsLeftAlert ? "rgb(253, 127, 130)" : "inherit" }}><b>Left:</b> {campaign.numAssignedNotContactedProspects || 0} (@: {campaign.numAssignedNotContactedProspectsWithEmail || 0})</span>&nbsp;&nbsp;
            <b>Gender Unknown:</b> {campaign.numGenderUnknown || 0}&nbsp;&nbsp;
            <b>Errors</b> { campaign.numConnectionErrorsAfterDeadlineWhereErrorsCount || 0 }&nbsp;&nbsp;

            <ExportCampaignModal campaign={campaign} />&nbsp;&nbsp;&nbsp;

            {accountLevelPreviewLinks()}

            { campaign.phantombusterErrors && <Message error header={"This campaign will never run."} content={campaign.phantombusterErrors} /> }
          </div>
        }
      </div>
    )
  }
}

class CampaignStatus extends React.Component {
  renderLastSevenDaysStatisticIcon() {
    return this.props.lastSevenDaysStatisticColor &&  <span>&nbsp;<i style={{ color: this.props.lastSevenDaysStatisticColor, fontSize: 15,}} className="fas fa-history"></i></span>
  }

  renderAlert() {
    console.log(this.props)
    let content = []
    if (!this.props.displayNoMoreProspectsLeftAlert && !this.props.displayCompanyCreditsEmptyAlert) {
      return
    }
    content.push()

    if (this.props.displayNoMoreProspectsLeftAlert) {
      content.push("no more prospects are left to contact")
    }

    if (this.props.displayCompanyCreditsEmptyAlert) {
      content.push("this company has no booked credits left")
    }

    return (
      <Popup on={'hover'} trigger={<span>&nbsp;<i style={{ color: "rgb(253, 127, 130)" }} className="fas fa-exclamation-circle"></i></span>} content={`No new ${this.props.campaign.scriptType === "LM" ? "messages" : "invitations"} will be sent because ` + content.join(" and ") + "."} />
    );
  }

  maybeRenderStatusText() {
    if (!this.props.cleanStyle) return null;

    let { status } = this.props.campaign;

    if (status == "1") {
      return <span className="statusText">Running</span>
    }
    if (status == "2") {
      return <span className="statusText">Paused w. followups</span>
    }
    if (status == "3") {
      return <span className="statusText">Paused fully</span>
    }
    if (status == "4") {
      return <span className="statusText">Finished w. fos</span>
    }
    if (status == "5") {
      return <span className="statusText">Finished fully</span>
    }
    if (status == "6") {
      return <span className="statusText">E.n. paused</span>
    }
    if (status == "7") {
      return <span className="statusText">Running</span>
    }
  }
  render () {
    let { status } = this.props.campaign;
    if (status == "1") {
      return (
        <div className={`status ${this.props.cleanStyle ? 'clean' : 'verbose'}`}>
          <span className="centerSymbol">
            <i className="far fa-play-circle"></i>
            { this.props.campaign.usesAddressBookUpload && <span>&nbsp;<i style={{fontSize: 18}} className="fa-solid fa-gauge-simple"></i></span> }
            { this.renderAlert() }
            { this.renderLastSevenDaysStatisticIcon() }
          </span>
          { this.maybeRenderStatusText() }
        </div>
      );
    }
    if (status == "2") {
      return (
        <div className="status" style={{color: "rgb(176, 186, 200)"}}>
          <span className="centerSymbol">
            <i className="far fa-pause-circle"></i>&nbsp;
            <i className="fas fa-comment-medical"></i>
            { this.renderLastSevenDaysStatisticIcon() }
          </span>
          { this.maybeRenderStatusText() }
        </div>
      );
    }
    if (status == "3") {
      return (
        <div className="status" style={{color: "rgb(176, 186, 200)"}}>
          <span className="centerSymbol">
            <i className="far fa-pause-circle"></i>&nbsp;
            <i className="fas fa-comment-slash font-minus-one"></i>
            { this.renderLastSevenDaysStatisticIcon() }
          </span>
          { this.maybeRenderStatusText() }
        </div>
      );
    }
    if (status == "4") {
      return (
        <div className="status" style={{color: "rgb(176, 186, 200)"}}>
          <span className="centerSymbol">
            <i className="far fa-check-circle"></i>&nbsp;
            <i className="fas fa-comment-medical"></i>
            { this.renderLastSevenDaysStatisticIcon() }
          </span>
          { this.maybeRenderStatusText() }
        </div>
      );
    }
    if (status == "5") {
      return (
        <div className="status" style={{color: "rgb(176, 186, 200)"}}>
          <span className="centerSymbol">
            <i className="far fa-check-circle"></i>&nbsp;
            <i className="fas fa-comment-slash font-minus-one"></i>
            { this.renderLastSevenDaysStatisticIcon() }
          </span>
          { this.maybeRenderStatusText() }
        </div>
      );
    }
    if (status == "6") {
      return (
        <div className="status" style={{color: "rgb(176, 186, 200)"}}>
          <span className="centerSymbol">
            <i className="fas fa-at"></i>&nbsp;
            <i className="fas fa-comment-medical"></i>
            { this.renderLastSevenDaysStatisticIcon() }
          </span>
          { this.maybeRenderStatusText() }
        </div>
      );
    }
    if (status == "7") {
      return (
        <div className="status">
          <span className="centerSymbol">
            <i className="far fa-play-circle"></i>&nbsp;
            { this.props.campaign.usesAddressBookUpload && <span><i style={{fontSize: 18}} className="fa-solid fa-gauge-simple"></i>&nbsp;</span> }
            <i style={{color: "rgb(176, 186, 200)"}} className="fab fa-creative-commons-zero"></i>
            { this.props.displayNoMoreProspectsLeftAlert &&  <span>&nbsp;<i style={{ color: "rgb(253, 127, 130)" }} className="fas fa-exclamation-circle"></i></span> }
            { this.renderLastSevenDaysStatisticIcon() }
          </span>
          { this.maybeRenderStatusText() }
        </div>
      );
    }

    return null;
  }
}

function statusReducer(campaign) {
  //if (campaign.nextMilestone && campaign.nextMilestone <= campaign.numDelivered) return "DONE";
  if (campaign.status === "1" || campaign.status === "6" || campaign.status === "7") {
    return "RUNNING";
  } else if (campaign.status === "2" || campaign.status === "3") {
    return "PAUSED";
  } else {
    return "DONE";
  }
}
