import React from "react";
import { Dropdown, Input, Form, } from 'semantic-ui-react'

export default class ProspectsLeftFilter extends React.Component {
  render () {
    let options = [
      { key: "50", text: "less than 50", value: "50" },
      { key: "100", text: "less than 100", value: "100" },
      { key: "250", text: "less than 250", value: "250" },
      { key: "500", text: "less than 500", value: "500" },
      { key: "1000", text: "less than 1000", value: "1000" },
    ];
    options.push({ key: "null", text: "any", value: "null" })
    return (

      <Form.Field>
        <label>Prospects left</label>
        <Dropdown
          placeholder='Prospects left'
          fluid
          selection
          options={options}
          search
          labeled
          value={this.props.prospectsLeft || "null"}
          onChange={this.props.onChange}
        />
      </Form.Field>
    )
  }
}
