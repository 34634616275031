import React from "react";
import { Header, Table, Form, Button, Icon, } from 'semantic-ui-react'
import ManuallyRequestedLinkedInOutreachForm from "./ManuallyRequestedLinkedInOutreachForm.js";


export default class ManuallyRequestedLinkedInOutreachFormSimpleOpenClose extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  onOpen() {
    this.setState({ open: true })
  }

  onClose() {
    this.setState({ open: false })
  }

  onSave(requestedOutreach) {
    this.props.onSave && this.props.onSave(requestedOutreach)
    this.setState({ open: false })
  }

  render() {
    let { defaultRequestedOutreach, trigger, campaigns, renderTemplateVariables, } = this.props;
    let { open } = this.state
    return (
      <ManuallyRequestedLinkedInOutreachForm
        trigger={trigger}
        defaultRequestedOutreach={defaultRequestedOutreach}
        renderTemplateVariables={renderTemplateVariables}
        campaigns={campaigns}
        open={open}
        onOpen={this.onOpen}
        resetOnOpen={true}
        onClose={this.onClose}
        onSave={this.onSave}
      />
    );
  }
}


