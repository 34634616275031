import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Popup, Dropdown, Icon, Input, Form, TextArea, Segment } from 'semantic-ui-react';
import _ from "underscore";
import TwilioDevice from "../Twilio/TwilioDevice";
import axios from "axios";
import Help from '../../frontend/ColdCalling/Help';

const CallPopup = (props) => {
  const { openCallSegment, handlers, prospectId } = props;

  const dpi = props.dataProviderInformation;
  const [phoneNumber, setPhoneNumber] = useState(dpi.userSanitizedValue || dpi.value || '');
  const [dataProviderInformation, setDataProviderInformation] = useState(dpi);

  const [tempNotes, setTempNotes] = useState('');
  const [notes, setNotes] = useState('');

  const [callInProgress, setCallInProgress] = useState(false);
  const [outcomes, setOutcomes] = useState([]);

  const [callData, setCallData] = useState({});

  const notesRef = useRef(null);
  const callOutcomeRef = useRef(null);

  const debouncedSetNotes = useCallback(
    _.debounce((value) => {
      setNotes(value);
    }, 300),
    []
  );

  const updateNotes = () => {
    if (callData.callSid) {
      axios.patch(`/phone_calls/${callData.callSid}/update_notes`, { notes: notes })
    }
  }

  useEffect(() => {
    updateNotes()
  }, [notes]);

  const updateOutcome = (outcomeId) => {
    callOutcomeRef.current = outcomeId
    if (callData.callSid) {
      axios.patch(`/phone_calls/${callData.callSid}/update_outcome`, { outcome_id: outcomeId })
    }
  }

  const getPhoneCallOutcomes = () => {
    axios.get(`/phone_calls/outcomes`).then(({ data }) => {
      setOutcomes(data);
    })
  }

  useEffect(() => {
    getPhoneCallOutcomes()
  }, []);

  useEffect(() => {
    if (!dataProviderInformation.id || !openCallSegment) return;

    axios.post(
      `/data_provider_information/${dataProviderInformation.id}`,
      { prospectId: prospectId, userSanitizedValue: phoneNumber }
    )
    setDataProviderInformation({ ...dataProviderInformation, userSanitizedValue: phoneNumber });
  }, [phoneNumber]);

  useEffect(() => {
    if (props.dataProviderInformation.value !== dataProviderInformation.value) {
      setDataProviderInformation(props.dataProviderInformation);
      setPhoneNumber(props.dataProviderInformation.userSanitizedValue || props.dataProviderInformation.value);
    }
  }, [props.dataProviderInformation]);

  const onClose = () => {
    handlers.setState({ openCallSegment: null })
    handlers.loadProspect();
  }

  return (
    <>
      {!openCallSegment && (
        <Button
          icon
          style={{ marginLeft: 5 }}
          onClick={() => handlers.setState({ openCallSegment: dataProviderInformation.value })}
        >
          <Icon name='phone' />
        </Button>
      )}
      {openCallSegment == dataProviderInformation.value && (
        <Segment
          style={{ minWidth: 460, maxWidth: 460, marginBottom: 20 }}
          id='callSegment'
          className="callSegment"
        >
          <Help style={{ marginTop: 0 }} />
          <TwilioDevice
            prospectId={prospectId}
            horizontal={true}
            setCallInProgress={setCallInProgress}
            selectedDataProviderInformation={dataProviderInformation}
            setCallData={setCallData}
          >
            <Input
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                setDataProviderInformation({ ...dataProviderInformation, userSanitizedValue: e.target.value });
              }}
              disabled={!!callInProgress}
              style={{ marginRight: 10 }}
            />
          </TwilioDevice>
          <Form.Field style={{ marginTop: 5 }}>
            <label>Call Outcome</label>
            <Dropdown
              fluid
              selection
              options={outcomes.map(o => ({ key: o.id, value: o.id, text: o.name }))}
              style={{ marginBottom: 10 }}
              onChange={(_e, element) => updateOutcome(element.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Notes</label>
            <TextArea
              value={tempNotes}
              onChange={(e) => {
                setTempNotes(e.target.value)
                debouncedSetNotes(e.target.value)
              }}
              ref={notesRef}
              style={{
                width: '100%',
                height: '100px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '8px',
                resize: 'none',
              }}
            />
            <Button disabled={callData.callId && !callData.ended} style={{ width: '100%' }} onClick={() => onClose()}>
              {callData.callId ? 'Save' : 'Close'}
            </Button>
          </Form.Field>
        </Segment>
      )}
    </>
  );
};

export default CallPopup;
