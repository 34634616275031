import React from "react";
import { Header, Table, Form, Button, Icon, Popup } from 'semantic-ui-react'
import CrmTaskForm from "./CrmTaskForm.js";
import { Link, withRouter,   } from "react-router-dom";
import BigCheckmark from "./BigCheckmark.js";
import axios from "axios";
import { present } from "./utils.js";
import moment from "moment";
import ThreadHistoryPopup from './ThreadHistoryPopup.js';
const queryString = require('query-string');



export default class CrmTaskList extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    let query = queryString.parse(this.props.location.search);
    let [sortField, sortDirection] = query['q[s]']?.split(' ') || [null, null]
    this.state = {
      activePopup: null,
      sortField,
      sortDirection,
    };
  }

  handlePopupActivation = (popupId) => {
    this.setState({ activePopup: popupId });
  };

  handleClose() {
    this.props.history.push(`/crm_tasks/${this.props.location.search}`)
  }

  handleSort(field) {
    let { sortField, sortDirection, } = this.state;

    let newSortDirection = null;

    if (sortField === field) {
      newSortDirection = sortDirection === "asc" && 'desc'
      newSortDirection ||= sortDirection === "desc" && 'asc'

      this.setState({ sortField: field, sortDirection: newSortDirection });
    } else {
      newSortDirection = 'asc'
      this.setState({ sortField: field, sortDirection: newSortDirection });
    }

    let query = queryString.parse(this.props.location.search);
    query['q[s]'] = `${field} ${newSortDirection || 'asc'}`
    this.props.history.push(this.props.location.pathname + "?" + queryString.stringify(query, { arrayFormat: 'bracket' }));
  }

  sortIndicator(field) {
    let { sortField, sortDirection, } = this.state;

    if (sortField === field) {
      return(<i className={sortDirection === "asc" ? "caret up icon" : "caret down icon"}></i>)
    }
  }

  render () {
    let crmTask = this.props.crmTasks.find(crmTask => Number(this.props.match.params.taskId) == crmTask.id)
    return (
      <React.Fragment>
        { present(this.props.match.params.taskId) &&
          <CrmTaskForm
            defaultCrmTask={present(crmTask) ? crmTask : { id: this.props.match.params.taskId }}
            defaultProspects={present(crmTask) ? [crmTask.prospect] : []}
            fetchCrmTask={true}
            users={this.props.users}
            onClose={this.handleClose}
            onSave={this.props.onCrmTaskRefreshed}
            open={true}
          />
        }
         { this.props.crmTasks.length > 0 && <Table celled>
          <Table.Header className="nowrap">
            <Table.Row>
              <Table.HeaderCell onClick={() => this.handleSort('completed')} style={{ cursor: 'pointer', textAlign: "center" }}>
                Status
                {this.sortIndicator('completed')}
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => this.handleSort('title')} style={{ cursor: 'pointer' }}>
                Title
                {this.sortIndicator('title')}
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => this.handleSort('prospect_id')} style={{ cursor: 'pointer' }}>
                Associated with
                {this.sortIndicator('prospect_id')}
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => this.handleSort('user_id')} style={{ cursor: 'pointer' }}>
                Assigned to
                {this.sortIndicator('user_id')}
              </Table.HeaderCell>
              <Table.HeaderCell>Threads</Table.HeaderCell>
              <Table.HeaderCell onClick={() => this.handleSort('due_date')} style={{ cursor: 'pointer' }}>
                Due date
                {this.sortIndicator('due_date')}
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.props.crmTasks.map(crmTask => {
                return <CrmTaskListItem
                  key={crmTask.id}
                  crmTask={crmTask}
                  crmStatuses={this.props.crmStatuses}
                  users={this.props.users}
                  onCrmTaskRefreshed={this.props.onCrmTaskRefreshed}
                  activePopup={this.state.activePopup}
                  handlePopupActivation={this.handlePopupActivation}
                />
              })
            }
          </Table.Body>
        </Table>
         }
      </React.Fragment>

    )
  }
}

CrmTaskList = withRouter(CrmTaskList)

class CrmTaskListItem extends React.Component {
  constructor(props) {
    super(props)
    this.handleOpen = this.handleOpen.bind(this);
    this.handleCompletedClick = this.handleCompletedClick.bind(this);
    this.findCrmStatus = this.findCrmStatus.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleOpen() {
    console.log("in handle open");
    let { crmTask, } = this.props;
    this.props.history.push(`/crm_tasks/${crmTask.id}${this.props.location.search}`)
  }

  handleDelete() {
    if (!window.confirm("Do you really want to delete this task?")) {
      return;
    }
    axios.delete(`/crm_tasks/${this.props.crmTask.id}`)
      .then(({ data }) => {
        if (data.success) {
          this.setState({ deleted: true })
        }
      })
      .catch(error => {
        console.log("error submitting", error);
        this.setState({ deleteLoading: false, deleteErrorMessage: "Could not delete task. We don't know why this error occured. Please try again or contact us :).", })
      });
  }


  handleCompletedClick(e) {
    axios.post(`/crm_tasks/${this.props.crmTask.id}/update`, {
      crmTask: { id: this.props.crmTask.id, completed: e.target.value, },
    })
      .then(({ data }) => {
        this.props.onCrmTaskRefreshed(data)
      })
      .catch(error => {
        console.log("error submitting", error);
      });
  }

  l(date) {
    if (!present(date)) return "-"
    return moment(date).format("DD.MM.YYYY")
  }

  isDue(date) {
    return moment(date).isBefore(moment.now())
  }


  findCrmStatus(id) {
    return this.props.crmStatuses.find(s => s.id == id)
  }

  render() {
    let { crmTask, users, } = this.props;
    let { prospect } = crmTask;
    console.log()
    let taskAssignee = users.find(u => u.id === crmTask.userId)

    if (this.state?.deleted) { return null; }

    return (
      <Table.Row>
        <Table.Cell>
          <BigCheckmark
            name="completed"
            value={crmTask.completed}
            onChange={this.handleCompletedClick}
          />
        </Table.Cell>
        <Table.Cell>
          { crmTask.title }
        </Table.Cell>
        <Table.Cell>
          <Link className="ui mini icon left labeled basic button" to={`/crm_tasks/prospects/${prospect.id}${this.props.location.search}`} style={{minWidth: "100%", textAlign: "left", }}>
            <Icon name="eye" className="verticalCenter"></Icon><span style={{whiteSpace: "nowrap"}}>{ prospect.name }</span><br /><i style={{fontSize: 10, whiteSpace: "nowrap", }}>{ present(prospect.crmStatusId) ? this.findCrmStatus(prospect.crmStatusId).name : "" }</i>
          </Link>
        </Table.Cell>
        <Table.Cell>
          { present(taskAssignee) ? taskAssignee.email : "-" }
        </Table.Cell>
        <Table.Cell>
          {
            prospect.prospectCampaignAssociations.map((assoc, i) =>
              <ThreadHistoryPopup
                displayOn='left'
                assoc={assoc}
                prospect={prospect}
                key={i}
                index={i}
                activePopup={this.props.activePopup}
                onPopupActivate={this.props.handlePopupActivation}
              >
                <a href={assoc.linkedInOutreach.threadUrl} target="_blank" style={{ display: "inline-block" }}>
                  {assoc.campaign.linkedInAccount.name}
                  {present(assoc.linkedInOutreach.repliedAt) ? " (REPLIED)" : ""}
                </a>
              </ThreadHistoryPopup>
            )
          }
        </Table.Cell>
        <Table.Cell>
          <span className={this.isDue(crmTask.dueDate) && !crmTask.completed ? "red" : ""}>
            { this.l(crmTask.dueDate) }
          </span>
        </Table.Cell>
        <Table.Cell style={{ textAlign: "center" }}>
          <div className="ui mini icon labeled vertical buttons">
            <Button labelPosition='left' size='mini' icon onClick={this.handleOpen}><Icon name="edit" ></Icon>Edit</Button>
            <Button icon='trash' content='Delete' onClick={this.handleDelete} />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
}

CrmTaskListItem = withRouter(CrmTaskListItem)
