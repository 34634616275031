import React from "react";
import { present, errorFor, formInput, adaptOnChangeToClassicFormOnChange } from "./utils.js";
import { Message, Modal, Button, Header, Icon, Form, Popup, Segment, Grid, } from 'semantic-ui-react';
import axios from "axios";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class ProspectsExportModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: null,
      exportInProgress: {
      },
      exportForm: {
      },
    }
    this.handleCreateExportClick = this.handleCreateExportClick.bind(this);
    this.pollExportContinously = this.pollExportContinously.bind(this);
    this.onExportFormChange = this.onExportFormChange.bind(this)
    this.pollExportContinously()
  }

  pollExportContinously() {
    if (!this.waitingForExport()) return setTimeout(this.pollExportContinously, 2000);

    axios.get(`/prospects_exports/${this.state.exportInProgress.id}`)
      .then(( { data }) => {
        this.setState({
          loading: data.status !== "DONE",
          exportInProgress: data,
        })
      })
      .catch(error => {
        console.log("error polling export ", error);
      })
      .finally(() => {
        setTimeout(this.pollExportContinously, 2000);
      })

  }

  handleCreateExportClick() {
    this.setState({ loading: true })
    axios.post('/prospects_exports/create', { ...this.state.exportForm, filterParams: this.props.filterParams,  })
      .then(({ data }) => {
        this.setState({ exportInProgress: data, errorMessage: null,  });
      })
      .catch(error => {
        this.setState({ loading: false, errorMessage: error.message });
      })
  }

  waitingForExport() {
    return present(this.state.exportInProgress.id) && this.state.exportInProgress.status !== "DONE"
  }

  onExportFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const nexExportForm = { ...this.state.exportForm}
    nexExportForm[name] = value
    this.setState({ exportForm: nexExportForm })
  }

  render() {
    let { campaign } = this.props;
    let { errorMessage, loading, exportInProgress, exportForm, } = this.state
    return (
      <Modal
        trigger={<Button size="mini" icon labelPosition='left'><Icon name='download' />Export</Button>}
        onOpen={this.handleCreateExportClick}
        closeIcon
      >
        <Modal.Header>Export prospects</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            { errorMessage && <Message
              error
              header={"An error occured"}
              content={errorMessage}
            /> }

            { present(exportInProgress.id) && <Segment>
                <ProspectsExport export={exportInProgress}/>
              </Segment>
            }

          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

class ProspectsExport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showCopyTooltip: false
    }
    this.handleCopy = this.handleCopy.bind(this)
  }

  handleCopy() {
    this.setState({showCopyTooltip: true})
    setTimeout(() => {
      this.setState({showCopyTooltip: false})
    }, 3000);
  }

  render() {
    let _export = this.props.export;
    if (!present(_export.id)) {
      return null;
    }
    if (_export.status !== "DONE") {
      return (
        <div>
          <p>Preparing Export - {_export.status}</p>
          <p>This may take several minutes.</p>
          <i>You can close this popup, we will alert you when the export is done.</i>
        </div>
      )
    }
    return (
      <div>
        <Header as="h3">Export ready!</Header>
        <Grid>
          { _export.googleSheetUrl && <Grid.Row>
              <Grid.Column>
                <Header as="h4">Google Sheet</Header>
                  <React.Fragment>
                    <Popup
                      content='Copied to clipboard!'
                      position='top center'
                      on='click'
                      open={this.state.showCopyTooltip}
                      trigger={<CopyToClipboard text={_export.googleSheetUrl}><Button onClick={this.handleCopy} icon labelPosition='left'><Icon name='clipboard' />Copy Sheet Url</Button></CopyToClipboard>}
                    />
                  </React.Fragment>
                  <a href={_export.googleSheetUrl} target="_blank">
                    <Button icon labelPosition='left'>
                      <Icon name='table' />
                      Open Google Sheet
                    </Button>
                  </a>
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
      </div>
    );
  }
}

