import React from "react";

import { Table, Form, Button, Icon, Dropdown, Tab, TabPane, Loader, Segment } from 'semantic-ui-react'
import { present, formInput } from "../utils";
import ProspectCrmStatusChange from "../ProspectCrmStatusChange";
import CrmTaskFormSimpleOpenClose from "../CrmTaskFormSimpleOpenClose";
import CrmTaskListWithUnroutedModal from "../CrmTaskListWithUnroutedModal";
import ManuallyRequestedLinkedInOutreachFormSimpleOpenClose from "../ManuallyRequestedLinkedInOutreachFormSimpleOpenClose";
import ManuallyRequestedLinkedInOutreachList from "../ManuallyRequestedLinkedInOutreachList";

const ColdCallignCardView = (props) => {
  const { data, handlers } = props;
  const prospect = data.prospect

  const renderCampaigns = () => (
    <>
      {
        prospect.prospectCampaignAssociations.map((assoc, i) => {
          return <Segment key={i}>
            <h3>{assoc.campaign.name}</h3>
            {handlers.renderAssoc(assoc, prospect, i, data.users)}
          </Segment>
        })
      }
    </>
  )

  const renderStatusHistory = () => {
    if (!present(prospect.crmStatusChanges)) return <p></p>;

    return (
      <React.Fragment>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Received at</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {prospect.crmStatusChanges.map((change, i) => {
              return <Table.Row key={i}>
                <Table.Cell style={{ width: "50%" }}>{change.crmStatusAfter ? change.crmStatusAfter.name : <i>no status</i>}</Table.Cell>
                <Table.Cell style={{ width: "50%" }}>{handlers.lHour(change.changedAt)}</Table.Cell>
              </Table.Row>
            })}
          </Table.Body>
        </Table>
      </React.Fragment>
    )
  }

  const panes = [
    { menuItem: 'Campaigns', render: () => <TabPane>{renderCampaigns()}</TabPane> },
    {
      menuItem: 'Tasks', render: () =>
        <TabPane>
          {data.crmTasks.length == 0 ? (
            <p>No tasks assigned to this prospect.</p>
          ) : (
            <CrmTaskListWithUnroutedModal
              crmTasks={data.crmTasks}
              users={data.users}
              onCrmTaskRefreshed={handlers.onCrmTaskRefreshed}
              hideAssociatedWith={true}
            />
          )}
          <CrmTaskFormSimpleOpenClose
            trigger={<Button size="mini" labelPosition='left' icon><Icon name="add"></Icon>Create Task</Button>}
            onSave={handlers.onNewTaskSaved}
            defaultCrmTask={{ prospectId: prospect.id, userId: data.currentUserId, }}
            defaultProspects={[prospect]}
            users={data.users}
          />
        </TabPane>
    },
    {
      menuItem: 'Manual connection requests', render: () => <TabPane>
        {prospect.manuallyRequestedLinkedInOutreaches.length == 0 ? (
          <p>No manual connection requests for this prospect.</p>
        ) : (
          <ManuallyRequestedLinkedInOutreachList
            manuallyRequestedLinkedInOutreaches={prospect.manuallyRequestedLinkedInOutreaches}
            campaigns={data.campaigns}
            onManuallyRequestedLinkedInOutreachRefreshed={handlers.onManuallyRequestedLinkedInOutreachChange}
            onManuallyRequestedLinkedInOutreachRemoved={handlers.onManuallyRequestedLinkedInOutreachRemoved}
            routed={false}
            crmStatuses={data.crmStatuses}
            hideGoingTo={true}
            hideCreatedAt={true}
          />
        )}
        <ManuallyRequestedLinkedInOutreachFormSimpleOpenClose
          trigger={<Button size="mini" labelPosition='left' icon><Icon name="add"></Icon>Create request</Button>}
          campaigns={data.campaigns}
          onSave={handlers.onManuallyRequestedLinkedInOutreachAdded}
          defaultRequestedOutreach={{
            triggeringProspectId: prospect.id,
            message: "",
            followUps: [],
            name: prospect.name,
            linkedInProfileUrl: prospect.linkedInProfileUrl,
            campaignId: null, // TODO: prefill the default campaign once we have the message variant data
          }}
          users={data.users}
        />
      </TabPane>
    },
  ]

  if (present(present(prospect.crmStatusChanges))) {
    panes.push({ menuItem: 'Status History', render: () => <TabPane>{renderStatusHistory()}</TabPane> })
  }


  return (
    <React.Fragment>
      <h3 style={{ marginTop: 17.5 }}>
        {present(prospect.linkedInProfileUrl) && <a href={prospect.linkedInProfileUrl} target="_blank" className="ui" aria-label="Profile"> <i className="fab fa-linkedin icon" style={{ fontFamily: "'Font Awesome 6 Brands'", color: "#2E384D" }}></i> </a>}
        {prospect.name}
      </h3>

      <React.Fragment>
        <Segment>
          <h3 style={{ lineHeight: "42.2px" }}> {handlers.renderCompanyLink(prospect)} - {prospect.title} </h3>

          {data.dataProviderInformation.map((dpi, i) => {
            if (dpi.type == "phone") return null;

            return (
              <p key={i}>
                {dpi.type == "email" && (
                  <>
                    <i className="fa-solid fa-envelope" style={{ marginRight: 5 }}></i>
                    <a href={`mailto:${dpi.value}`}>{dpi.value}</a>
                  </>
                )}
                <span style={{ marginLeft: 5, color: 'gray' }}>({dpi.source})</span>
              </p>
            )
          })}

          {!present(data.addDataProviderInformation) && (
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              <Button
                content="Add Email Address"
                size="mini"
                onClick={() => handlers.setState({ addDataProviderInformation: { type: 'email' } })}
              />
            </div>
          )}

          {present(data.addDataProviderInformation) && (
            <Form.Field style={{ marginBottom: 10 }}>
              <Form.Input type='text' action>
                <i
                  className={`fa-solid fa-${data.addDataProviderInformation.type == 'email' ? 'envelope' : 'phone'}`}
                  style={{ marginRight: 5, marginTop: 12 }}
                ></i>
                <input
                  type={data.addDataProviderInformation.type == 'email' ? 'email' : 'tel'}
                  value={data.addDataProviderInformation?.userSanitizedValue || data.addDataProviderInformation?.value || ''}
                  onChange={(e) => handlers.setState({ addDataProviderInformation: { ...data.addDataProviderInformation, value: e.target.value, userSanitizedValue: e.target.value, } })}
                />
                <Button onClick={() => handlers.handleDataProviderInformationCreation(data.addDataProviderInformation)}>Save</Button>
                {data.addDataProviderInformation.id && (
                  <Button
                    color="red"
                    onClick={() => handlers.handleDataProviderInformationDeletion(data.addDataProviderInformation)}
                  >
                    Delete
                  </Button>
                )}
                <Button basic onClick={() => handlers.setState({ addDataProviderInformation: {} })}>Cancel</Button>
              </Form.Input>
            </Form.Field>
          )}

          <Form>
            <ProspectCrmStatusChange
              prospect={prospect}
              crmStatuses={data.crmStatuses}
              users={data.users}
              onProspectChange={handlers.onProspectChangeWithoutNotes}
              label={"Crm Status"}
            />
            <Form.Field>
              <label>Tags</label>
              <Dropdown
                multiple
                search
                options={
                  data.tags.map(tag => { return { key: tag.id, text: tag.name, value: tag.id } }).sort(
                    (a, b) => a.text.localeCompare(b.text)
                  )
                }
                value={prospect.tagIds}
                allowAdditions
                onAddItem={(_, { value }) => handlers.handleTagCreation(value)}
                selection
                onChange={(_, { value }) => handlers.setState({ prospect: { ...prospect, tagIds: value.filter(v => !isNaN(v)) } })}
                selectOnBlur={false}
                clearSearchQueryAfterItemAdd={true}
              />
            </Form.Field>
            {
              formInput(
                prospect,
                "notes",
                "Notes",
                handlers.onProspectFormChange,
                Form.TextArea,
              )
            }
          </Form>
        </Segment>

        <Tab panes={panes} style={{ marginTop: 20 }} />

        {data.loading && <Loader active inline='centered' />}


      </React.Fragment>
    </React.Fragment>
  )
}

export default ColdCallignCardView;
