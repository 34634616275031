import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';

const Help = ({ style = {} }) => {
  let trigger = <Icon
    name='question circle'
    size="large"
    style={{ cursor: 'pointer', position: 'fixed', right: 20, marginTop: 20, zIndex: 999999, ...style }}
  />

  return (
    <Popup
      trigger={trigger}
      position="left center"
    >
      You can start/end the call with a keyboard shortcut. Press <strong>Ctrl&nbsp;+&nbsp;Shift&nbsp;+&nbsp;E</strong> to start/end the call.
    </Popup>
  );
};

export default Help;
