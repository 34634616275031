import React from "react";

import { present, } from "../shared/utils.js";
import { Message, } from 'semantic-ui-react';

export default class FixedMessage extends React.Component {
  render () {
    return (
      <div style={{
        position: "fixed",
        right: 20,
        top: 10,
        zIndex: 1000000000000000000000000,
        width: "300px",
        padding: "10px",
      }}>
      <Message {...this.props} />
    </div>

    )
  }
}
