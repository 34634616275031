import React from "react";
import { Grid, Progress, } from 'semantic-ui-react';

export default class CreditsProgress extends React.Component {
  render () {
    let { usedCredits, totalCredits } = this.props
    if (usedCredits === null) {
      usedCredits = 0;
    }
    if (totalCredits === null) {
      totalCredits = 0;
    }
    let error = totalCredits > 0 && (totalCredits-usedCredits < 250)

    let percent = totalCredits === 0 ? 100 : Math.round(100*usedCredits/totalCredits)
    return (
      <Grid.Column width={16}>
        <Progress percent={percent} size='small' error={error} progress>
          {usedCredits}/{totalCredits}
        </Progress>
      </Grid.Column>
    )
  }
}
