import React from 'react'
import { Dropdown, Input, Form, } from 'semantic-ui-react'

export default class CampaignFilter extends React.Component {
  render () {
    let options = this.props.campaigns.map(c => {
      return {
        key: ""+c.id,
        value: ""+c.id,
        text: c.name,
      }
    })
    options = [ ...options ]
    return (

      <Form.Field>
        <label>Campaigns Filter</label>
        <Dropdown
          placeholder='Campaigns'
          fluid
          multiple
          selection
          options={options}
          search
          labeled
          value={this.props.filteredCampaigns || []}
          onChange={this.props.onChange}
        />
      </Form.Field>
    )
  }
}

