import React from "react";

import { BrowserRouter as Router, Route, Link, Switch, withRouter, } from "react-router-dom";
import FilteredProspects from "./FilteredProspects.js";
import ProspectView from "./ProspectView.js";


export default class ProspectsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prospects: [],
      totalProspects: 0,
      tags: this.props.tags || [],
    }
    this.handleProspectsChange = this.handleProspectsChange.bind(this)
    this.singleProspectChange = this.singleProspectChange.bind(this)
  }

  handleProspectsChange(prospects, totalProspects) {
    this.setState({ prospects, totalProspects })
  }

  singleProspectChange(prospect) {
    let prospects = [...this.state.prospects];
    let index = prospects.findIndex(p => p.id === prospect.id)
    if (index < 0) return console.log("prospect not found");
    prospects[index] = {...prospects[index], ...prospect}
    this.setState({ prospects })
  }

  render () {
    return (
      <Router>
        <Route
          path="/prospects/:prospectId/"
          render={(routeProps) =>
            <ProspectView
              crmStatuses={this.props.crmStatuses}
              users={this.props.users}
              id={routeProps.match.params.prospectId}
              onClose={() => { routeProps.history.push(`/prospects${routeProps.location.search}`) } }
              currentUserId={this.props.currentUserId}
              onProspectChange={this.singleProspectChange}
              campaigns={this.props.campaigns}
              currentUserTwilioPhoneNumber={this.props.currentUserTwilioPhoneNumber}
              tags={this.state.tags}
              setTags={tags => this.setState({ tags })}
            />
          }
        />
        <Switch>
          <Route
            path="/prospects"
            render={() => <FilteredProspects
              prospects={this.state.prospects || []}
              totalProspects={this.state.totalProspects}
              onProspectsChange={this.handleProspectsChange}
              onProspectChange={this.singleProspectChange}
              crmStatuses={this.props.crmStatuses}
              campaigns={this.props.campaigns}
              linkedInAccounts={this.props.linkedInAccounts}
              users={this.props.users}
              prospectsEnrichments={this.props.prospectsEnrichments}
              currentUserId={this.props.currentUserId}
              companyId={this.props.companyId}
              currentUserCanSeeProspectDetails={this.props.currentUserCanSeeProspectDetails}
              currentUserCanTriggerDataProviderEnrichment={this.props.currentUserCanTriggerDataProviderEnrichment}
              currentUserCanSeeDataProviderData={this.props.currentUserCanSeeDataProviderData}
              dataProviders={this.props.dataProviders}
              tags={this.state.tags}
            />}
          />
        </Switch>
      </Router>
    )
  }
}


