import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Indicator = styled.div`
  width: calc(10% - 10px);
  height: 10px;
  display: inline-block;
  margin: 5px;
`

function AudioMeter(props) {
  const [activePids, setActivePids] = useState(0);
  let audioContextRef = useRef(null);
  let intervalRef = useRef(null);
  let streamRef = useRef(null);

  let [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (!props.meteredDevice) { return; }
    if (intervalId) {
      clearInterval(intervalId);
    }

    const constraints = {
      audio: { deviceId: props.meteredDevice ? { exact: props.meteredDevice } : undefined },
      video: false
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

        audioContextRef.current = audioContext;

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);

        let intrId = setInterval(() => {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          let sum = 0;

          for (let i = 0; i < array.length; i++) {
            sum += array[i];
          }

          const average = sum / array.length;

          setActivePids(Math.round(average / 10));
        }, 100);

        intervalRef.current = intrId;
        streamRef.current = stream;
      })
      .catch(err => {
        console.error(err);
      });
  }, [props.meteredDevice]);

  useEffect(() => {
    setActivePids(props.level * 10)
  }, [props.level]);

  useEffect(() => {
    return async () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    }
  }, [])

  return (
    <div style={{ marginTop: 10 }}>
      {Array.from({ length: 10 }, (_, i) => (
        <Indicator
          key={i}
          style={{
            backgroundColor: i < activePids ? "#69ce2b" : "#e6e7e8"
          }}
        />
      ))}
    </div>
  );
}

export default AudioMeter;
