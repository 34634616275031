import React from "react";
import { Form, Dropdown, } from 'semantic-ui-react';

export default class TextAreaWithInsertableVariables extends React.Component {
  constructor(props) {
    super(props);
    this.state = { }
    this.handleInsertTemplateVar = this.handleInsertTemplateVar.bind(this)
    this.handleTextAreaShortcuts = this.handleTextAreaShortcuts.bind(this)
  }

  getStringWithTemplateVar(string, templateVar, selectionStart, selectionEnd) {
    if (!string) { return templateVar; }
    return string.substring(0, selectionStart) + templateVar + string.substring(selectionEnd, string.length);
  }

  componentDidUpdate() {
    if (this.cursorSetter) {
      this.cursorSetter && this.cursorSetter()
      this.cursorSetter = null;
    } 
  }

  handleInsertTemplateVar(templateVar) {
    templateVar = templateVar.replace(/\r/g, "")
    let originalSelectionStart = this.textareaRef.selectionStart || 0;
    let originalSelectionEnd = this.textareaRef.selectionEnd || 0;
    this.cursorSetter = () => {
      let textarea = this.textareaRef;
      textarea.focus();
      textarea.setSelectionRange(originalSelectionStart+templateVar.length, originalSelectionStart+templateVar.length);
    };

    this.props.onChange({
      target: {
        value: this.getStringWithTemplateVar(this.props.value, templateVar, originalSelectionStart, originalSelectionEnd),
        name: this.props.name,
      }
    })
  }

  getTextareaRef(wrapper) {
    if (!wrapper) { return; }
    let textarea = wrapper.getElementsByTagName('textarea')[0]
    this.textareaRef = textarea;
  }

  handleTextAreaShortcuts(event) {
    const shortcutKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
    if (event.ctrlKey && shortcutKeys.includes(event.key)) {
      this.handleInsertTemplateVar(this.props.variables.find(t => t.shortcut === event.key).value)
    }
  }

  render () {
    let variablesWithDescription = this.props.variables.map(v => {
      return {
        ...v,
        description: v.description || (v.shortcut && `Ctrl + ${v.shortcut}`)
      }
    });
    let { dropdownStyle, TextAreaComponent, ...textAreaProps } = this.props
    if (!TextAreaComponent) {
      TextAreaComponent = Form.TextArea
    }
    return (
      <div ref={this.getTextareaRef.bind(this)} className="noFieldMargin">
        <TextAreaComponent
          {...textAreaProps} 
          onKeyDown={this.handleTextAreaShortcuts}
        />
        { variablesWithDescription.length > 0 &&
          <Dropdown 
            text='Variables' 
            style={dropdownStyle || { top: 5, float: 'right' }} 
            search 
            selectOnNavigation={false}
            selectOnBlur={false}
            options={variablesWithDescription} 
            onChange={(e, data) => { this.handleInsertTemplateVar(data.value) }}
            onKeyDown={this.handleTextAreaShortcuts}
            value={null}
          /> 
        }
      </div>
    )
  }
}
