import React from 'react'
import { Dropdown, Input, Form, } from 'semantic-ui-react'

export default class CrmStatusFilter extends React.Component {
  render () {
    let options = this.props.crmStatuses.map(s => {
      return {
        key: ""+s.id,
        value: ""+s.id,
        text: s.name,
      }
    })
    options = [ { key: "null", value: "null", text: "No Status" }, ...options ]
    return (

      <Form.Field>
        <label>Crm Status</label>
        <Dropdown
          placeholder='Crm Status Filter'
          fluid
          multiple
          selection
          options={options}
          search
          labeled
          value={this.props.filteredCrmStatuses || []}
          onChange={this.props.onChange}
        />
      </Form.Field>
    )
  }
}

