import React from 'react'
import { Dropdown, Input, Form, } from 'semantic-ui-react'

export default class UserFilter extends React.Component {
  render () {
    let options = this.props.users.map(u => {
      return {
        key: ""+u.id,
        value: ""+u.id,
        text: u.email,
      }
    })
    options = [ { key: "null", value: "null", text: "No one" }, ...options ]
    return (

      <Form.Field>
        <label>{this.props.label || "Assigned to"}</label>
        <Dropdown
          placeholder='Assigned to'
          multiple
          fluid
          selection
          options={options}
          search
          labeled
          value={this.props.filteredUsers || []}
          onChange={this.props.onChange}
        />
      </Form.Field>
    )
  }
}


