import React from 'react'
import { Dropdown, Input, Form, } from 'semantic-ui-react'

export default class CompletedStatusFilter extends React.Component {
  render () {
    let options = [
      {
        key: "false",
        value: "false",
        text: "Open",
      },
      {
        key: "true",
        value: "true",
        text: "Completed",
      },
    ];
    return (

      <Form.Field>
        <label>Task Status</label>
        <Dropdown
          placeholder='Status'
          fluid
          multiple
          selection
          options={options}
          search
          labeled
          value={this.props.statuses || []}
          onChange={this.props.onChange}
        />
      </Form.Field>
    )
  }
}



