import React from "react";
import { present, errorFor, formInput, adaptOnChangeToClassicFormOnChange } from "./utils.js";
import { Message, Modal, Button, Header, Icon, Form, Popup, Segment, Grid, } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from "axios";

export default class ExportCampaignModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: null,
      export_in_progress: {
        campaignId: props.campaign.id,
      },
      export_form: {
        campaignId: props.campaign.id,
        mode: "de",
        shouldCreateGoogleSheet: true,
      },
    }
    this.handleCreateExportClick = this.handleCreateExportClick.bind(this);
    this.pollExportContinously = this.pollExportContinously.bind(this);
    this.onExportFormChange = this.onExportFormChange.bind(this)
    this.pollExportContinously()
  }

  pollExportContinously() {
    if (!this.waitingForExport()) return setTimeout(this.pollExportContinously, 2000);

    axios.get(`/backend/campaign_exports/${this.state.export_in_progress.id}`)
      .then(( { data }) => {
        this.setState({
          loading: data.status !== "DONE",
          export_in_progress: data,
        })
      })
      .catch(error => {
        console.log("error polling export ", error);
      })
      .finally(() => {
        setTimeout(this.pollExportContinously, 2000);
      })

  }

  handleCreateExportClick() {
    this.setState({ loading: true })
    axios.post('/backend/campaign_exports/create', this.state.export_form)
      .then(({ data }) => {
        this.setState({ export_in_progress: data, errorMessage: null,  });
      })
      .catch(error => {
        this.setState({ loading: false, errorMessage: error.message });
      })
  }

  waitingForExport() {
    return present(this.state.export_in_progress.id) && this.state.export_in_progress.status !== "DONE"
  }

  onExportFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const nexExportForm = { ...this.state.export_form}
    nexExportForm[name] = value
    this.setState({ export_form: nexExportForm })
  }

  render() {
    let { campaign } = this.props;
    let { errorMessage, loading, export_in_progress, export_form, } = this.state
    return (
      <Modal trigger={<span style={{color: "#4183C4", cursor: "pointer"}}>Export</span>}>
        <Modal.Header>Export {campaign.name}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            { errorMessage && <Message
              error
              header={"An error occured"}
              content={errorMessage}
            /> }

            <Segment>
              <Header>Configure</Header>

              <Form>
                {
                  formInput(
                    export_form,
                    "mode",
                    "Mode",
                    this.onExportFormChange,
                    Form.Select,
                    {
                      options: [
                        {key: "de", value: "de", text: "German"},
                        {key: "en", value: "en", text: "English"},
                        {key: "mail_follow_up", value: "mail_follow_up", text: "Mail Follow Up"},
                        {key: "pc", value: "pc", text: "Verbose Computer Readable"},
                        {key: "pb_db_immitation", value: "pb_db_immitation", text: "PB immitation"},
                      ],
                    }
                  )
                }
                { formInput(export_form, "shouldCreateGoogleSheet", "Create Google Sheet?", this.onExportFormChange, Form.Checkbox, { slider: true } ) }
                <Button loading={loading} onClick={this.handleCreateExportClick}>Create Export</Button>
              </Form>
            </Segment>

            { present(export_in_progress.id) && <Segment>
                <CampaignExport export={export_in_progress}/>
              </Segment>
            }

          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

}

class CampaignExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCopyTooltip: false
    }
    this.handleCopy = this.handleCopy.bind(this)
  }

  handleCopy() {
    this.setState({showCopyTooltip: true})
    setTimeout(() => {
      this.setState({showCopyTooltip: false})
    }, 3000);
  }

  render() {
    let _export = this.props.export;
    if (!present(_export.id)) {
      return null;
    }
    if (_export.status !== "DONE") {
      return (
        <div>
          <span>Preparing Export - {_export.status}</span>
        </div>
      )
    }
    return (
      <div>
        <Header as="h3">Export ready!</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">CSVs</Header>
              <a href={_export.csvUrl}>
                <Button icon labelPosition='left'>
                  <Icon name='file' />
                  Download CSV
                </Button>
              </a>
              <a href={_export.zipUrl}>
                <Button icon labelPosition='left'>
                  <Icon name='folder' />
                  Download Batched CSV
                </Button>
              </a>
            </Grid.Column>
          </Grid.Row>
          { _export.shouldCreateGoogleSheet && <Grid.Row>
              <Grid.Column>
                <Header as="h4">Google Sheet</Header>
                  <React.Fragment>
                    <Popup
                      content='Copied to clipboard!'
                      position='top center'
                      on='click'
                      open={this.state.showCopyTooltip}
                      trigger={<CopyToClipboard text={_export.googleSheetUrl}><Button onClick={this.handleCopy} icon labelPosition='left'><Icon name='clipboard' />Copy Sheet Url</Button></CopyToClipboard>}
                    />
                  </React.Fragment>
                  <a href={_export.googleSheetUrl} target="_blank">
                    <Button icon labelPosition='left'>
                      <Icon name='table' />
                      Open Google Sheet
                    </Button>
                  </a>
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
      </div>
    );
  }
}
