import React from "react";
import { Header, Table, Form, Button, Icon, } from 'semantic-ui-react'
import ProspectForm from "./ProspectForm.js";


export default class ProspectFormSimpleOpenClose extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  onOpen() {
    this.setState({ open: true })
  }

  onClose() {
    this.setState({ open: false })
  }

  onSave(prospect) {
    this.props.onSave(prospect)
    this.setState({ open: false })
  }

  render() {
    let { defaultProspect, trigger, campaigns, } = this.props;
    let { open } = this.state
    return (
      <ProspectForm
        trigger={trigger}
        defaultProspect={defaultProspect}
        campaigns={campaigns}
        open={open}
        onOpen={this.onOpen}
        resetOnOpen={true}
        onClose={this.onClose}
        onSave={this.onSave}
      />
    );
  }
}

