import React from 'react'
import { Header, Table, Form, Button, Icon, Modal, Grid, Popup, Dropdown, } from 'semantic-ui-react'
import { BrowserRouter as Router, Route, Link, Switch, withRouter, } from "react-router-dom";
import { present, formInput, renderProspectCampaignAssocForTableView, } from "./utils.js";
import truncate from "truncate";
import CrmTaskForm from "./CrmTaskForm.js";
import CrmTaskFormSimpleOpenClose from "./CrmTaskFormSimpleOpenClose.js";
import axios from "axios";
import _ from "underscore"
import ProspectCrmStatusChange from "./ProspectCrmStatusChange.js";
import ThreadHistoryPopup from './ThreadHistoryPopup.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class ProspectList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activePopup: null,
    };
  }

  handlePopupActivation = (popupId) => {
    this.setState({ activePopup: popupId });
  };

  renderProspectPreviews() {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Campaigns</Table.HeaderCell>
            <Table.HeaderCell>Threads</Table.HeaderCell>
            <Table.HeaderCell>CRM-Status</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.props.prospects.map((prospect) => {
              return <ProspectListItem
                key={prospect.id}
                prospect={prospect}
                crmStatuses={this.props.crmStatuses}
                onProspectChange={this.props.onProspectChange}
                users={this.props.users}
                currentUserId={this.props.currentUserId}
                activePopup={this.state.activePopup}
                handlePopupActivation={this.handlePopupActivation}
              />
            })
          }
        </Table.Body>
      </Table>
    );



  }

  render () {
    return (
      <div className="prospectList">
        <div>
          { this.renderProspectPreviews() }
        </div>
      </div>
    )
  }
}



class ProspectListItem extends React.Component {
  frontendCompanyUrl(url) {
    return url.replace("/sales", "")
  }

  renderCompanyLink(prospect) {
    var name = present(prospect.primaryCompanyName) ? truncate(prospect.primaryCompanyName, 30) : ""
    if (present(prospect.primaryCompanyLinkedinUrl)) {
      return (
        <a href={this.frontendCompanyUrl(prospect.primaryCompanyLinkedinUrl)} target="_blank">{name}</a>
      )
    } else {
      return (
        <React.Fragment> {name} </React.Fragment>
      )
    }
  }



  render() {
    let { prospect, crmStatuses, users, onProspectChange, activePopup, handlePopupActivation } = this.props;

    let dataProviderInformation = prospect.dataProviderInformation || []
    dataProviderInformation = dataProviderInformation.filter((dpi, i) =>
      dataProviderInformation.findIndex(dpi2 => (dpi.value === dpi2.value)) === i
    )

    let phones = dataProviderInformation.filter(dpi => dpi.type == 'phone')
    let emails = dataProviderInformation.filter(dpi => dpi.type == 'email')

    return (
      <React.Fragment>
        <Table.Row>
          <Table.Cell style={{ whiteSpace: 'nowrap' }}>
            <span title={prospect?.name?.length > 80 && prospect.name || ''}>
              {prospect.name?.slice(0, 80)} {prospect.name?.length > 80 ? "..." : ""}
            </span>
            &nbsp;&nbsp;
            {present(prospect.linkedInProfileUrl) && <a href={prospect.linkedInProfileUrl} target="_blank" className="ui" aria-label="Profile">
              <i className="fab fa-linkedin icon" style={{ fontFamily: "'Font Awesome 6 Brands'", color: "#2E384D" }}></i>
            </a>
            }
            {present(emails) &&
              <React.Fragment>
                <Popup
                  trigger={
                    <span>
                      {
                        emails.length > 1 ? (
                          <span style={{ marginLeft: 3, marginRight: 3 }}><i className="far fa-solid fa-envelope"></i></span>
                        ) : (
                          <span style={{ cursor: 'pointer' }}>
                            <CopyToClipboard text={emails[0].value}
                              onCopy={() => {
                                this.setState({ copiedEmail: emails[0].value })
                                setTimeout(() => (this.setState({ copiedEmail: null })), 2000)
                              }}
                            >
                              <span style={{ marginLeft: 3, marginRight: 3 }}><i className="far fa-solid fa-envelope"></i></span>
                            </CopyToClipboard>
                          </span>
                        )
                      }
                    </span>
                  }
                  on={'hover'}
                  mouseLeaveDelay={200}
                  position={"top center"}
                  hoverable={true}
                  style={{ width: 'auto' }}
                  content={
                    <div>
                      {
                        emails.map((email, i) => (
                          <div style={{ textAlign: 'left', marginTop: 5, marginBottom: 5 }} title='Click to copy' key={i}>
                            <CopyToClipboard text={email.value}
                              onCopy={() => {
                                this.setState({ copiedEmail: email.value })
                                setTimeout(() => (this.setState({ copiedEmail: null })), 2000)
                              }}
                            >
                              <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'left', justifyContent: 'left',  }}>
                                <p style={{ display: 'inline', whiteSpace: 'nowrap'  }}>
                                  <i className="far fa-solid fa-envelope" style={{ marginRight: 5 }}></i>
                                  <span>{email.value}</span>
                                  <span style={{ textTransform: 'capitalize', color: 'grey', marginLeft: 5 }}>({email.source})</span>
                                  {(this.state && this.state.copiedEmail == email.value) ? (
                                    <Popup
                                      content='Copied!'
                                      open={true}
                                      flowing
                                      trigger={<i className="far fa-solid fa-check" style={{ marginLeft: 5 }}></i>}
                                      position={"bottom center"}
                                      style={{ width: 70 }}
                                      inverted
                                    />
                                  ) : (
                                    <i className="far fa-solid fa-copy" style={{ marginLeft: 5 }}></i>
                                  )}
                                </p>
                              </div>
                            </CopyToClipboard>
                          </div>
                        ))
                      }
                    </div>
                  }
                />
              </React.Fragment>
            }
            {present(phones) &&
              <React.Fragment>
                <Popup
                  trigger={
                    <span>
                      {phones.length > 1 ? (
                        <span style={{ marginLeft: 5, marginRight: 3 }}><i className="far fa-solid fa-phone"></i></span>
                      ) : (
                        <span style={{ cursor: 'pointer' }}>
                          <CopyToClipboard text={phones[0].value}
                          onCopy={() => {
                            this.setState({ copiedPhone: phones[0].value})
                            setTimeout(() => (this.setState({ copiedPhone: null })), 2000)
                          }}
                        >
                          <span style={{ marginLeft: 5, marginRight: 3 }}><i className="far fa-solid fa-phone"></i></span>
                        </CopyToClipboard>
                        </span>
                      )}
                    </span>
                  }
                  mouseLeaveDelay={200}
                  position={"top center"}
                  hoverable={true}
                  on={'hover'}
                  style={{ width: 'auto' }}
                  content={
                    <div>
                      {phones.map((phone, i) => (
                        <div style={{ textAlign: 'left', marginTop: 5, marginBottom: 5 }} title='Click to copy' key={i}>
                          <CopyToClipboard text={phone.value}
                            onCopy={() => {
                              this.setState({ copiedPhone: phone.value })
                              setTimeout(() => (this.setState({ copiedPhone: null })), 2000)
                            }}
                          >
                            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                              <p style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                                <i className="far fa-solid fa-phone" style={{ marginRight: 5 }}></i>
                                <span>{phone.value}</span>
                                <span style={{ textTransform: 'capitalize', color: 'grey', marginLeft: 5 }}>({phone.source})</span>

                                {(this.state && this.state.copiedPhone == phone.value) ? (
                                  <Popup
                                    content='Copied!'
                                    open={true}
                                    flowing
                                    trigger={<i className="far fa-solid fa-check" style={{ marginLeft: 5 }}></i>}
                                    position={"bottom center"}
                                    style={{ width: 70 }}
                                    inverted
                                  />
                                ) : (
                                  <i className="far fa-solid fa-copy" style={{ marginLeft: 5 }}></i>
                                )}
                              </p>
                            </div>
                          </CopyToClipboard>
                        </div>
                      ))}
                    </div>
                  }
                />

              </React.Fragment>
            }
            {present(prospect.notes) &&
                <React.Fragment>
                  <Popup
                    trigger={ <span>&nbsp; <i className="far fa-sticky-note"></i></span> }
                    mouseLeaveDelay={100}
                    position={"right center"}
                    wide={"very"}
                    style={{ padding: 20 }}
                    pinned={true}
                    hoverable={true}
                    content={
                      <div
                            style={{ maxHeight: 500, width: 600, maxWidth: "none", padding: 15, paddingTop: 60, overflowY: "auto", position: "relative", }}
                            ref={e => { if (e !== null) { e.scrollTop = e.scrollHeight } } }
                          >
                        <h3 style={{position: "fixed", paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10, borderBottom: "1px solid rgba(34, 36, 38, 0.15)", top: 0, left: 0, right: 0, width: "calc(100% - 18px)", background: "white", }}>
                          { <a href={prospect.linkedInProfileUrl} target="_blank" className="ui" aria-label="Profile">
                            <i className="fab fa-linkedin icon" style={{fontFamily: "'Font Awesome 6 Brands'", color: "#2E384D"}}></i>
                          </a>
                          }
                          &nbsp;
                          {prospect.name} <span style={{fontSize: 14}}>Notes</span>
                        </h3>
                        <div style={{whiteSpace: "pre-wrap", paddingLeft: 10, }}>
                          {prospect.notes}
                        </div>
                        <div style={{paddingLeft: 10, }}>
                          <Link className="" to={`/prospects/${prospect.id}${this.props.location.search}`}>
                            Edit
                          </Link>
                        </div>
                      </div>
                    }
                  />

                </React.Fragment>
            }
        </Table.Cell>
          <Table.Cell>
            { truncate(prospect.title, 30) }
          </Table.Cell>
          <Table.Cell>
            { this.renderCompanyLink(prospect) }
          </Table.Cell>
          <Table.Cell>
            { prospect.prospectCampaignAssociations.map((assoc, i) => {
              return <div key={i}>
                { assoc.campaign.name }
              </div>
            }) }
          </Table.Cell>
          <Table.Cell>
            {
              prospect.prospectCampaignAssociations.map((assoc,i) =>
                <ThreadHistoryPopup
                  displayOn='left'
                  assoc={assoc}
                  prospect={prospect}
                  key={i}
                  index={i}
                  activePopup={activePopup}
                  onPopupActivate={handlePopupActivation}
                >
                  <a href={assoc.linkedInOutreach.threadUrl} target="_blank" style={{display: "inline-block"}}>
                    { assoc.campaign.linkedInAccount.name }
                    { present(assoc.linkedInOutreach.repliedAt) ? " (REPLIED)" : "" }
                  </a>
                </ThreadHistoryPopup>
              )
            }
          </Table.Cell>
          <Table.Cell>
            <ProspectCrmStatusChange
              prospect={prospect}
              crmStatuses={crmStatuses}
              users={users}
              onProspectChange={onProspectChange}
            />
          </Table.Cell>
          <Table.Cell>
            <Button.Group vertical labeled icon>
              <Link className="ui mini icon left labeled button" to={`/prospects/${prospect.id}${this.props.location.search}`}>
                <Icon name="eye"></Icon>View&nbsp;({prospect.numOpenTasks})
              </Link>
              <CrmTaskFormSimpleOpenClose
                trigger={<Button labelPosition='left' size='mini' icon><Icon name="add"></Icon>Task</Button>}
                defaultProspects={[prospect]}
                defaultCrmTask={{prospectId: prospect.id, userId: this.props.currentUserId, }}
                onSave={(data) => {
                  if (present(data.prospect)) {
                    this.props.onProspectChange(data.prospect)
                  }
                }}
                users={users}
              />
            </Button.Group>

          </Table.Cell>
        </Table.Row>
      </React.Fragment>
    );
  }
}

ProspectListItem = withRouter(ProspectListItem)

