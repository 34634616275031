import React from "react";

import { BrowserRouter as Router, Route, Switch, } from "react-router-dom";
import CallInterface from "./CallInterface";
import { Container, Table } from "semantic-ui-react";
import Index from "./Index";

export default class ColdCallingDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render () {
    return (
      <Router>
        <Switch>
          <Route
            path="/cold_calling/:campaignGroupId/:scheduledPhoneCallId"
            render={(routeParams) =>
              <CallInterface
                phoneCallOutcomes={this.props.phoneCallOutcomes}
                campaignGroupId={routeParams.match.params.campaignGroupId}
                scheduledPhoneCallId={routeParams.match.params.scheduledPhoneCallId}
                scheduledPhoneCall={this.props.scheduledPhoneCall}
                crmStatuses={this.props.crmStatuses}
                users={this.props.users}
                currentUserId={this.props.currentUserId}
                campaigns={this.props.campaigns}
                tags={this.props.tags}
              />
            }
          />
          <Route
            path="/cold_calling/:campaignGroupId"
            render={(routeProps) =>
              <CallInterface
                campaignGroupId={routeProps.match.params.campaignGroupId}
                phoneCallOutcomes={this.props.phoneCallOutcomes}
              />
            }
          />
          <Route
            path="/cold_calling"
            render={() =>
              <Index
                campaignGroups={this.props.campaignGroups}
                prospectDistributionRunning={this.props.prospectDistributionRunning}
              />
            }
          />
        </Switch>
      </Router>
    )
  }
}
