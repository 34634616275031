import React from 'react'
import { Dropdown, Input, Form, } from 'semantic-ui-react'

export default class CampaignStatusFilter extends React.Component {
  render () {
    let options = [
      {
        key: "REPLIED",
        text: "replied",
        value: "REPLIED",
      },
      {
        key: "NOT_REPLIED",
        text: "not replied",
        value: "NOT_REPLIED",
      },
      {
        key: "ACCEPTED",
        text: "accepted",
        value: "ACCEPTED",
      },
      {
        key: "NOT_ACCEPTED",
        text: "not accepted",
        value: "NOT_ACCEPTED",
      },
    ]
    return (

      <Form.Field>
        <label>Campaign Status</label>
        <Dropdown
          placeholder='Campaign Status Filter'
          fluid
          multiple
          selection
          options={options}
          search
          labeled
          value={this.props.filteredCampaignStati || []}
          onChange={this.props.onChange}
        />
      </Form.Field>
    )
  }
}
