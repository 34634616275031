import React, { useEffect, useState, useCallback } from 'react';
import { Input, Popup, Table, Icon, TextArea } from 'semantic-ui-react';
import axios from 'axios';
import { debounce } from "debounce";
import { present } from "../../shared/utils.js";

const PhoneNumberControl = (props) => {
  const { phones, setPhones, callInProgress, scheduledPhoneCallId, prospectId, selectedDataProviderInformation, setSelectedDataProviderInformation } = props;

  const [phoneNumber, setPhoneNumber] = useState(selectedDataProviderInformation?.userSanitizedValue || selectedDataProviderInformation?.value || '');
  const [addingPhoneNumber, setAddingPhoneNumber] = useState(false);
  const [phoneNumberToAdd, setPhoneNumberToAdd] = useState({ value: '', notes: '' });
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setPhoneNumber(selectedDataProviderInformation?.userSanitizedValue || selectedDataProviderInformation?.value || '');
  }, [selectedDataProviderInformation]);

  const updatePhoneNumber = (value) => {
    setPhoneNumber(value)
    axios.post(
      `/data_provider_information/${selectedDataProviderInformation.id}`,
      { prospectId: prospectId, userSanitizedValue: value }
    )
    let newValue = { ...selectedDataProviderInformation, userSanitizedValue: value }
    // code smell, it's better to have a single data storage, and only reference that using ids, that way we wouldn't have
    // to update two things here
    let newPhones = [...phones]
    newPhones[newPhones.findIndex(p => p.id === selectedDataProviderInformation.id)] = newValue
    setPhones(newPhones)
    setSelectedDataProviderInformation(newValue)
  }

  const debouncedNotesUpdate = useCallback(
    debounce((dataProviderInformationId, prospectId, notes) => {
      axios.post(`/data_provider_information/${dataProviderInformationId}/notes`, { notes, prospectId })
    }, 300),
    []
  );

  const updatePhoneNumberNotes = (dataProviderInformationId, notes) => {
    debouncedNotesUpdate(dataProviderInformationId, prospectId, notes)

    let newPhones = [...phones]
    newPhones[newPhones.findIndex(p => p.id === dataProviderInformationId)].notes = notes
    setPhones(newPhones)
  }

  const forbidPhoneNumber = (id) => {
    if (window.confirm('Are you sure you want to forbid this phone number?')) {
      setModalOpen(false)
      axios.post(
        `/data_provider_information/${id}/forbid`,
        { prospectId, scheduledPhoneCallId }
      ).then(() => {
        let newPhones = phones.filter(p => p.id != id)
        setPhones(newPhones)
        if (selectedDataProviderInformation.id == id) {
          setSelectedDataProviderInformation(newPhones[0])
        }
      }).catch(error => {
        console.error('Can not forbid phone number: ', error)
      })
    }
  }

  const handlePhoneCreation = () => {
    axios.post(
      '/data_provider_information',
      { type: 'phone', value: phoneNumberToAdd.value, notes: phoneNumberToAdd.notes, prospectId: prospectId }
    ).then((data) => {
      setPhones([...phones, data.data])
      setAddingPhoneNumber(false)
      setPhoneNumberToAdd({ value: '', notes: '' })
      if (!present(selectedDataProviderInformation)) {
        setSelectedDataProviderInformation(data.data)
      }
    }).catch(error => {
      console.error("error when creating data provider information ", error);
    });
  }

  return (
    <>
      {callInProgress && (
        <Input
          value={phoneNumber}
          disabled
          icon='dropdown'
        />
      )}
      {!callInProgress && (
        <Popup
          trigger={
            <Input
              icon={!present(selectedDataProviderInformation) ? 'exclamation circle' : 'dropdown'}
              value={!present(selectedDataProviderInformation) ? '' : phoneNumber}
              onChange={(e) => {
                if (present(selectedDataProviderInformation)) {
                  updatePhoneNumber(e.target.value)
                  setSelectedDataProviderInformation({ ...selectedDataProviderInformation, userSanitizedValue: e.target.value, value: e.target.value });
                }
              }}
              id="phone-number-popup-trigger"
              autoComplete="off"
            />
          }
          on="click"
          position="bottom center"
          wide={"very"}
          style={{ marginTop: 18, overflowY: 'auto', overflowX: 'clip', maxHeight: 550 }}
          onClose={() => setModalOpen(false)}
          onOpen={() => setModalOpen(true)}
          open={modalOpen}
          id="phone-number-popup"
        >
          {phones.length == 0 && !addingPhoneNumber && (
            <p>You must add any phone number to continue with calling.</p>
          )}
          {(phones.length > 0 || addingPhoneNumber) && (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Phone</Table.HeaderCell>
                  <Table.HeaderCell>Source</Table.HeaderCell>
                  <Table.HeaderCell>Calls</Table.HeaderCell>
                  <Table.HeaderCell>Quality</Table.HeaderCell>
                  <Table.HeaderCell>Notes</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {phones.map(phone => (
                  <Table.Row key={phone.id} id={`dpi-${phone.id}`}>
                    <Table.Cell>
                      {(phone.userSanitizedValue || phone.value).replace(/ /g, '\u00A0')}&nbsp;
                      {!present(phone.userSanitizedValue) && phone.originalValue && phone.originalValue != phone.value && (
                        <Popup
                          content={`Original value: ${phone.originalValue}`}
                          position="top center"
                          trigger={<Icon name='history'/>}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>{phone.humanizedDataProviderType}</Table.Cell>
                    <Table.Cell>{phone.callCount || 0}</Table.Cell>
                    <Table.Cell>{phone.quality || 'Unknown'}</Table.Cell>
                    <Table.Cell>
                      <TextArea
                        cols="25"
                        rows="4"
                        value={phone.notes || ''}
                        style={{ border: '1px solid rgba(34, 36, 38, 0.15)', resize: 'none', boxSizing: 'border-box' }}
                        onChange={(e) => updatePhoneNumberNotes(phone.id, e.target.value)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {phone.id != selectedDataProviderInformation?.id && (
                        <p
                          style={{ cursor: 'pointer', color: 'rgb(65, 131, 196)' }}
                          onClick={() => {
                            setSelectedDataProviderInformation(phone)
                            setModalOpen(false)
                          }}
                        >
                          Use
                        </p>
                      )}
                      <p
                        style={{ cursor: 'pointer', color: 'rgb(65, 131, 196)' }}
                        onClick={() => forbidPhoneNumber(phone.id)}
                      >
                        Forbidden
                      </p>
                    </Table.Cell>
                  </Table.Row>
                ))}
                {addingPhoneNumber && (
                  <Table.Row key=''>
                    <Table.Cell>
                      <Input
                        type='tel'
                        value={phoneNumberToAdd.value}
                        onChange={(e) => setPhoneNumberToAdd({ ...phoneNumberToAdd, value: e.target.value })}
                      />
                    </Table.Cell>
                    <Table.Cell>You</Table.Cell>
                    <Table.Cell>-</Table.Cell>
                    <Table.Cell>-</Table.Cell>
                    <Table.Cell>
                      <TextArea
                        cols="25"
                        rows="4"
                        value={phoneNumberToAdd.notes}
                        style={{ border: '1px solid rgba(34, 36, 38, 0.15)', resize: 'none', boxSizing: 'border-box' }}
                        onChange={(e) => setPhoneNumberToAdd({ ...phoneNumberToAdd, notes: e.target.value })}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <p
                        style={{ cursor: 'pointer', color: 'rgb(65, 131, 196)' }}
                        onClick={() => handlePhoneCreation()}
                      >
                        Save
                      </p>
                      <p
                        style={{ cursor: 'pointer', color: 'rgb(65, 131, 196)' }}
                        onClick={() => {
                          setAddingPhoneNumber(false)
                          setPhoneNumberToAdd('')
                        }}
                      >
                        Cancel
                      </p>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          )}
          {!addingPhoneNumber && (
            <p
              style={{ cursor: 'pointer', color: 'rgb(65, 131, 196)' }}
              onClick={() => {
                setAddingPhoneNumber(true)
                setPhoneNumberToAdd({ value: '', notes: '' })
              }}
            >
              Add Phone Number
            </p>
          )}
        </Popup>
      )}
    </>
  )
}

export default PhoneNumberControl;
