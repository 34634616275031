import React from "react";
import { Dropdown, Input, Form } from "semantic-ui-react";

export default class SearchFilter extends React.Component {
  render() {
    let options = this.props.availableSearches.map((search) => {
      return {
        key: String(search.id),
        text: search.name,
        value: String(search.id),
      };
    });
    // options.push({ key: "null", text: "Without search", value: "null" });
    return (
      <Form.Field>
        <label>Assigned searches</label>
        <Dropdown
          placeholder="Search Filter"
          fluid
          multiple
          selection
          options={options}
          search
          labeled
          value={this.props.filteredSearches || []}
          onChange={this.props.onChange}
        />
      </Form.Field>
    );
  }
}
