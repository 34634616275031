import React from "react";
import { Form, } from 'semantic-ui-react';
import { present, formInput, } from "../shared/utils.js";
import axios from "axios";
import _ from "underscore";
const queryString = require('query-string'); // TODO: replace with "qs" used in ProspectsFilter.js


export default class ProspectSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      prospects: []
    }
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleSearchChange(e, { searchQuery }) {
    this.fetchProspects(searchQuery);
  }

  fetchProspects(search) {
    let searchWithPage = "?"+queryString.stringify({ search, page: 1 }, { arrayFormat: 'bracket' });
    let path = `/prospects.json${searchWithPage}`
    this.setState({ loading: true })
    axios.get(path)
      .then(({ data }) => {
        let newProspects = _.uniq([ ...this.state.prospects, ...this.prospectsToOptions(data.prospects) ], false, p => p.value)
        this.setState({
          loading: false,
          prospects: newProspects,
        });
      })
  }

  prospectsToOptions(prospects) {
    if (!present(prospects)) return [];
    return prospects.map(p => { return { key: p.id, value: p.id, text: `${p.name} - ${p.primaryCompanyName || ""}` } });
  }

  prospects() {
    return _.uniq(this.state.prospects.concat(this.prospectsToOptions(this.props.defaultProspects)), false, p => p.value)
  }

  render () {
    let { model, name, label, onChange } = this.props;
    let { loading,  } = this.state;
    return (
      <React.Fragment>
        {
          formInput(
            model,
            name,
            label,
            onChange,
            Form.Select,
            {
              options: this.prospects(),
              onSearchChange: this.handleSearchChange,
              loading,
              fluid: true,
              selection: true,
              search: true,
            }
          )
        }
      </React.Fragment>
    )
  }
}
