import React from 'react'

export default class SwitchPercentageAbsolute extends React.Component {
  percentString() {
    if (this.props.perMille) {
      return (1000*(this.props.part/this.props.full)).toFixed(2)+"‰";
    } else {
      return Math.round(100*(this.props.part/this.props.full))+"%";
    }
  }

  render () {
    if (this.props.part === null) {
      return (
      <div aria-label={this.props["aria-label"]} style={this.props.style}>
        <div>
          n/a
        </div>
      </div>
      );
    }
    if (!this.props.full) {
      return (
      <div aria-label={this.props["aria-label"]} style={this.props.style}>
        <div>
          { this.props.part }
        </div>
      </div>
      );
    }
    return (
      <div className="hoverSwitch" style={this.props.style}>
        <div className="visibleOffHover" aria-label={this.props["aria-label"]}>
          { this.percentString() }
        </div>
        <div className="visibleOnHover" aria-label={this.props["aria-label"]}>
          { this.props.part }
        </div>
      </div>
    )
  }
}
