import React from 'react';
import { Header, Placeholder, } from 'semantic-ui-react';
import StatisticsTable from "../../frontend/StatisticsTable.js";


function GeneralTemplate(props) {
  let { present, model, loading, relevantQueryStatistics, relevantSearchStatistics, relevantCampaignStatistics, showConversion } = props;

  return(
    <>
      {
        present(model.messageVariantStatistics) && !loading &&
        <div>
          <Header as="h2" style={{ marginTop: 60, marginBottom: 30 }}>Message Variants</Header>
          <StatisticsTable
            firstColName="Name"
            statistics={model.messageVariantStatistics}
            periodType={"monthly"}
            showConversion={showConversion}
          />
        </div>
      }

      {
        present(relevantCampaignStatistics) && !loading &&
        <div>
          <Header as="h2" style={{ marginTop: 60, marginBottom: 30 }}>Campaigns</Header>
          <StatisticsTable
            firstColName="Campaigns"
            statistics={relevantCampaignStatistics}
            periodType={"campaigns"}
            showConversion={showConversion}
          />
        </div>
      }

      { present(relevantSearchStatistics) && !loading &&
          <div>
            <Header as="h2" style={{ marginTop: 60, marginBottom: 30 }}>Searches</Header>
            <StatisticsTable
              firstColName="Search"
              statistics={relevantSearchStatistics}
              periodType={"searches"}
              showConversion={showConversion}
            />
          </div>
      }

      <Header as="h2" style={{ marginTop: 60, marginBottom: 30 }}>Monthly View</Header>
      {
        loading ?
        <div>
          {
            [0, 1, 2, 3, 4, 5].map((i) => {
              return <div
                key={i}
                style={{ boxShadow: "2px 2px 22px #c2e1f1", fontSize: "18px", background: "#fff", marginBottom: "50px", borderRadius: "9px", padding: "20px 20px 20px 25px", border: "2px solid #53c1f8", }}
              >
                <Placeholder length={"full"} style={{ width: "100%", maxWidth: "none" }}>
                  <Placeholder.Header>
                    <Placeholder.Line length={"very long"} />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line length={"medium"} />
                  </Placeholder.Header>
                </Placeholder>
              </div>
            })
          }
        </div>
        :
        <StatisticsTable
          firstColName="Month"
          statistics={model.monthlyStatistics}
          periodType={"monthly"}
          showConversion={showConversion}
        />
      }

      {
        present(relevantQueryStatistics) &&
        <div>
          <Header as="h2" style={{ marginTop: 60, marginBottom: 30 }}>Queries</Header>
          <StatisticsTable
            firstColName="Query"
            statistics={relevantQueryStatistics}
            periodType={"queries"}
            showConversion={showConversion}
          />
        </div>
      }

      {
        (present(model.segmentedStatistics)) &&
        <div>
          <Header as="h2" style={{ marginTop: 60, marginBottom: 30 }}>Segments</Header>
          <StatisticsTable
            firstColName="Name"
            statistics={model.segmentedStatistics}
            periodType={"segments"}
            showConversion={showConversion}
          />
        </div>
      }
    </>
  )
}

export default GeneralTemplate;
