import React from "react";

export default class BigCheckmark extends React.Component {
  handleChange() {
    this.props.onChange({ target: { name: this.props.name, value: !this.props.value } })
  }

  render () {
    return (
      <div className={`checkmarkWrapper ${this.props.value ? "checked" : "unchecked"}`} onClick={this.handleChange.bind(this)}>
        <i className="fas fa-check"></i>
      </div>
    )
  }
}
